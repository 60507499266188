import React, { useState, useContext } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { getAccessToken } from "../helpers/storage";
import { SystemContext } from '../helpers/SystemContext';

const generateCardId = () => {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100) + "-" + Math.floor(Math.random() * (999 - 100 + 1) + 100);
};

const AddVipUserScreen = () => {
    const navigation = useNavigation();
    const { fetchSystemData } = useContext(SystemContext);
    const [cardId, setCardId] = useState(generateCardId());
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [creditPercentage, setCreditPercentage] = useState('');
    const [serviceFee, setServiceFee] = useState(false);

    const handleSave = async () => {
        const token = await getAccessToken();
        const vipUserData = {
            cardId: cardId,
            startAmount: 0,
            creditPercentage: Number(creditPercentage || 0),
            discountAmount: Number(discountPercentage || 0),
            enableServiceFee: serviceFee || true,
            user: {
                name: {
                    first: firstName,
                    last: lastName,
                },
                phone: phoneNumber,
            },
        };

        try {
            const response = await fetch('https://api.happygastro.hu/api/vipcard/register', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify(vipUserData),
            });

            if (!response.ok) {
                throw new Error('Failed to save VIP user');
            }

            const data = await response.json();
            await fetchSystemData(); // Fetch updated data
            navigation.goBack();
        } catch (error) {
            console.error('Error saving VIP user:', error);
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <Text style={styles.label}>Kártya</Text>
            <TextInput style={styles.input} value={cardId} onChangeText={setCardId} />

            <View style={styles.row}>
                <View style={styles.halfInputContainer}>
                    <Text style={styles.label}>Vezetéknév</Text>
                    <TextInput style={styles.input} value={lastName} onChangeText={setLastName} />
                </View>
                <View style={styles.halfInputContainer}>
                    <Text style={styles.label}>Keresztnév</Text>
                    <TextInput style={styles.input} value={firstName} onChangeText={setFirstName} />
                </View>
            </View>

            <Text style={styles.label}>Telefonszám</Text>
            <TextInput style={styles.input} value={phoneNumber} onChangeText={setPhoneNumber} keyboardType="phone-pad" />

            <Text style={styles.label}>E-mail cím</Text>
            <TextInput style={styles.input} value={email} onChangeText={setEmail} keyboardType="email-address" />

            <Text style={styles.label}>Kedvezmény</Text>
            <TextInput style={styles.input} value={discountPercentage} onChangeText={setDiscountPercentage} keyboardType="numeric" />

            <Text style={styles.label}>Jóváírás</Text>
            <TextInput style={styles.input} value={creditPercentage} onChangeText={setCreditPercentage} keyboardType="numeric" />

            <View style={styles.checkboxContainer}>
                <Checkbox
                    status={serviceFee ? 'checked' : 'unchecked'}
                    onPress={() => setServiceFee(!serviceFee)}
                />
                <Text style={styles.checkboxLabel}>Service Fee</Text>
            </View>

            <View style={styles.buttonContainer}>
                <Button title="Mégsem" onPress={() => navigation.goBack()} />
                <Button style={{ backgroundColor: "orange" }} title="Mentés" onPress={handleSave} color="#f76e24" />
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 5,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 15,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    halfInputContainer: {
        flex: 1,
        marginRight: 10,
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
    },
    checkboxLabel: {
        marginLeft: 8,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default AddVipUserScreen;