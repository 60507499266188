import React, { useContext } from "react";
import { FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Surface } from "react-native-paper";
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { SystemContext } from "../helpers/SystemContext";
import { priceFormatter } from "../helpers";

const VipUserScreen = ({ navigation }) => {
    const { vipCards } = useContext(SystemContext);
    const { width } = useWindowDimensions();
    const { t } = useTranslation();

    const handlePress = (vipCard) => {
        navigation.navigate('VipUserDetailScreen', { vipCard });
    };

    return (
        <LinearGradient colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']} style={styles.background}>
            <View style={{ height: '96vh', flex: 1, display: 'flex' }}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, maxHeight: "90vh" }}>
                    <FlatList
                        data={vipCards}
                        renderItem={({ item }) => {
                            const phoneNumber = item.user?.phoneNumbers?.[0]?.phone || t('n_a');
                            const email = item.user?.email || t('n_a');
                            const transactionCount = item.history?.length || 0;

                            return (
                                <TouchableOpacity style={styles.row} onPress={() => handlePress(item)}>
                                    <Surface style={styles.surface} elevation={4}>
                                        <View style={styles.iconContainer}>
                                            <FontAwesome5 name="user-circle" size={50} color={item.balance < 0 ? 'red' : 'green'} />
                                        </View>
                                        <View style={styles.infoContainer}>
                                            {width > 600 ? (
                                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                                    <Text style={styles.nameText}>
                                                        {(item.user.name && (item.user.name.first || item.user.name.last))
                                                            ? `${item.user.name.first} ${item.user.name.last}`
                                                            : `${t('id')}: ${item.cardId}`}
                                                    </Text>
                                                    <Text style={[styles.creditText, item.balance < 0 ? styles.negativeCredit : styles.positiveCredit]}>
                                                        {t('balance')}: {priceFormatter(item.balance)}
                                                    </Text>
                                                </View>
                                            ) : (
                                                <>
                                                    <Text style={styles.nameText}>
                                                        {(item.user.name && (item.user.name.first || item.user.name.last))
                                                            ? `${item.user.name.first} ${item.user.name.last}`
                                                            : `${t('id')}: ${item.cardId}`}
                                                    </Text>
                                                    <Text style={[styles.creditText, item.balance < 0 ? styles.negativeCredit : styles.positiveCredit]}>
                                                        {t('balance')}: {item.balance}
                                                    </Text>
                                                </>
                                            )}
                                            <View style={styles.detailsContainer}>
                                                <View style={styles.detailItem}>
                                                    <MaterialIcons name="email" size={20} color="gray" />
                                                    <Text style={styles.infoText}>{email}</Text>
                                                </View>
                                                <View style={styles.detailItem}>
                                                    <MaterialIcons name="phone" size={20} color="gray" />
                                                    <Text style={styles.infoText}>{phoneNumber}</Text>
                                                </View>
                                                <View style={styles.detailItem}>
                                                    <MaterialIcons name="history" size={20} color="gray" />
                                                    <Text style={styles.infoText}>{t('transactions')}: {transactionCount}</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.detailItem, { justifyContent: 'flex-end' }]}>
                                                <MaterialIcons name="access-time" size={20} color="gray" />
                                                <Text style={[styles.lastUsedText, { textAlign: 'right' }]}>{new Date(item.lastUsed).toLocaleString()}</Text>
                                            </View>
                                        </View>
                                    </Surface>
                                </TouchableOpacity>
                            );
                        }}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={styles.listContainer}
                        keyboardShouldPersistTaps="handled"
                    />
                </ScrollView>
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    surface: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        width: '95%',
        marginVertical: 8,
        alignSelf: 'center',
        borderRadius: 10,
        backgroundColor: '#f7f5f5',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
    },
    listContainer: {
        flexGrow: 1,
        paddingBottom: 45,
        minHeight: '100%',
    },
    background: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    iconContainer: {
        marginRight: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoContainer: {
        flex: 1,
        justifyContent: 'space-between',
    },
    detailsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    detailItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 15,
        marginVertical: 5,
    },
    nameText: {
        fontWeight: "700",
        fontSize: 20,
        color: '#333',
        marginBottom: 5,
        marginRight: 10,
    },
    creditText: {
        fontWeight: "600",
        fontSize: 18,
    },
    positiveCredit: {
        color: 'green',
    },
    negativeCredit: {
        color: 'red',
    },
    infoText: {
        fontWeight: "500",
        fontSize: 16,
        color: 'gray',
        marginLeft: 5,
    },
    lastUsedText: {
        fontWeight: "500",
        fontSize: 16,
        color: 'gray',
        marginLeft: 5,
    },
});

export default VipUserScreen;