import React, { useState, useEffect, useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View, Dimensions, StyleSheet } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import IonIcon from 'react-native-vector-icons/Ionicons';
import SaleScreen from "./SaleScreen";
import CartScreen from "./CartScreen";
import { CartContext } from '../helpers/CartContext';
import BigSaleScreen from './BigSaleScreen';
import SuccessModal from "./SuccessModal";
import {useNavigation, useRoute} from "@react-navigation/native";
import FailModal from "./FailModal";
import FunctionsScreen from "./FunctionsScreen";
import { TableContext } from "../helpers/TableContext";
import CircularButton from '../components/CircularButton';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SystemContext } from "../helpers/SystemContext";
import { VipCardContext } from "../helpers/VipCardContext";
import { OnlineOrderContext } from "../helpers/OnlineOrderContext";
import OrdersScreen from "./OrdersScreen";
import { OrdersContext } from "../helpers/OrdersContext";
import StatisticsHelper from "../helpers/StatisticsHelper";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import Observables from "@happy-gastro/global-observables";

const Tab = createBottomTabNavigator();
const { width } = Dimensions.get('window');

export default function HomeScreen({ }) {
    const { t } = useTranslation();
    const { currentTable, removeTable } = useContext(TableContext);
    const { cartItems = [], paymentModalVisible, setPaymentModalVisible, setAlreadySentCartItems, alreadySentCartItems, setDiscountsForWholeCart } = useContext(CartContext);
    const route = useRoute();
    const { setSelectedCategory, setSelectedEvent, fetchSystemData } = useContext(SystemContext);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [failModalVisible, setFailModalVisible] = useState(false);
    const [order, setOrder] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const { removeVipCard } = useContext(VipCardContext);
    const { resetOnlineOrder } = useContext(OnlineOrderContext);

    const navigation = useNavigation();


    useEffect(() => {
        if(route.params?.saleScreen && width > 768) navigation.navigate('BigSale', {...route.params});
        if(route.params?.saleScreen && width < 768) navigation.navigate('Cart', {...route.params});
    }, [navigation, route.params]);

    useEffect(() => {
        if (route.params?.userInfo) {
            setUserInfo(route.params.userInfo);
        }
    }, [route.params]);
    useEffect(() => {},[alreadySentCartItems])

    useEffect(() => {
        if (route.params?.paymentSuccess !== undefined) {
            if (route.params?.paymentSuccess === true) {
                setOrder(route?.params?.orderResponse)
                setSuccessModalVisible(true);
                setPaymentModalVisible(false);
                navigation.navigate('Sale');
            } else {
                setFailModalVisible(true);
                setPaymentModalVisible(false);
            }
        }
    }, [route.params]);

    useEffect(() => {
        if (route.params?.navigateToSale) {
            navigation.navigate('SaleScreen');
        }
    }, [route.params]);

    const handleCloseModal = () => {
        setSuccessModalVisible(false);
        setFailModalVisible(false);
        navigation.setParams({ paymentSuccess: undefined });
    };

    const handleCloseSuccessModal = async () => {
        setSuccessModalVisible(false);
        setFailModalVisible(false);
        navigation.setParams({ paymentSuccess: undefined });
        if (currentTable) {
            Observables.call("reloadOrders");
            Observables.call("refreshOrders");
            console.log(order)
            const tableOrder = orders?.find(
                (order) =>
                    !order.storno &&
                    order.status !== "cancelled" &&
                    order.status !== "closed" &&
                    order.tableId === currentTable?._id
            );
            if(tableOrder?.items && tableOrder?.items.length > 0){
                navigation.navigate('HomeScreen');
                fetchSystemData();
                removeVipCard();
                resetOnlineOrder();
                console.log('tableOrder', tableOrder);
                setAlreadySentCartItems(tableOrder.items)
            }else{
                navigation.navigate('TablesScreen');
                removeTable();
                setAlreadySentCartItems([]);
                setSelectedCategory(null);
                setSelectedEvent(null);
                await AsyncStorage.removeItem('currentTable');
                setDiscountsForWholeCart({ amount: 0, percentage: 0 });
                removeVipCard();
                fetchSystemData();
                resetOnlineOrder();
            }
        }else{
            removeTable();
            setAlreadySentCartItems([]);
            setSelectedCategory(null);
            setSelectedEvent(null);
            await AsyncStorage.removeItem('currentTable');
            setDiscountsForWholeCart({ amount: 0, percentage: 0 });
            removeVipCard();
            resetOnlineOrder();
            fetchSystemData();
            resetOnlineOrder();
        }
    };

    const { orders } = useContext(OrdersContext);
    const { productsSum, statistics } = StatisticsHelper(orders);

    const shownOrders = [...statistics.onlineOrders, ...statistics.unClosedTakeAwayOrders, ...statistics.dineInOrders].filter(order => !order.storno || order.status !== 'closed' || order.status !== 'cancelled');

    return (
        <View style={{ flex: 1, width: '100%', height: '100%', flexGrow: 1 }}>
            <Tab.Navigator
                initialRouteName="Sale"
                detachInactiveScreens={false}
                tabBarLabelPosition={"below-icon"}
                lazy={false}
                backBehavior={"firstRoute"}
                swipeEnabled={true}
                headerMode={"none"}
                screenOptions={{ headerShown: false }}
                tabBarOptions={{
                    activeTintColor: '#a26909',
                    inactiveTintColor: '#575757',
                    activeBackgroundColor: '#f0f0f0',
                    inactiveBackgroundColor: '#ffffff',
                    showLabel: true,
                    labelStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                    tabStyle: {
                        paddingVertical: 10,
                        height: 70,
                        marginTop: -20
                    },
                    style: {
                        height: 70,
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                {width < 768 ? (
                    <>
                        <Tab.Screen name="Sale" component={SaleScreen}
                                    options={{
                                        tabBarIcon: ({ color, size }) => (
                                            <Icon name="home" color={color} size={size} />
                                        ),
                                        title: t('sales'),
                                        tabBarLabel: t('sales'),
                                    }} />
                        <Tab.Screen name="Cart" component={CartScreen}
                                    options={{
                                        tabBarIcon: ({ color, size }) => (
                                            <Icon name="shopping-basket" color={color} size={size} />
                                        ),
                                        title: t('cart'),
                                        tabBarLabel: t('cart'),
                                        ...((cartItems.length > 0) && { tabBarBadge: cartItems.length || 0,
                                            tabBarBadgeStyle: { minWidth: "fit-content", height: "fit-content", borderRadius: 14, justifyContent: 'center', alignItems: 'center', overflow: "show" }  }),
                                    }} />
                    </>
                ) : (
                    <Tab.Screen name="BigSale" component={BigSaleScreen}
                                options={{
                                    tabBarIcon: ({ color, size }) => (
                                        <Icon name="home" color={color} size={size} />
                                    ),
                                    title: t('sales'),
                                    tabBarLabel: t('sales'),
                                    numColumns: 3,
                                }} />
                )}
                <Tab.Screen name="HomeOrdersList" component={OrdersScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <IonIcon name="bag-sharp" color={color} size={size} />
                                ),
                                title: t('open_orders'),
                                tabBarLabel: t('open_orders'),
                                tabBarBadge: (shownOrders?.length || 0),
                                tabBarBadgeStyle: shownOrders?.length === 0 ? { display: 'none' } : { minWidth: "fit-content", height: "fit-content", borderRadius: 14, justifyContent: 'center', alignItems: 'center', overflow: "show" }
                            }}
                            initialParams={{ name: "OrdersList" }}
                />
                <Tab.Screen name="Functions" component={FunctionsScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <Icon name="server" color={color} size={size} />
                                ),
                                title: t('functions'),
                                tabBarLabel: t('functions'),
                            }} />
            </Tab.Navigator>

            <CircularButton navigation={navigation} iconName="grid" />

            <SuccessModal visible={successModalVisible} order={order} onClose={handleCloseSuccessModal} />
            <FailModal visible={failModalVisible} onClose={handleCloseModal} />
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});