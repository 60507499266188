import React, {createContext, useContext, useState} from 'react';
import {SystemContext} from "./SystemContext";

export const VipCardContext = createContext();


export const VipCardProvider = ({ children }) => {
    const [selectedVipCard, setSelectedVipCard] = useState(null);
    const { vipCards, fetchSystemData } = useContext(SystemContext);



    const selectVipCard = (vipCard) => {
        const wholeVIPObject = vipCards.find(v => v.id === vipCard.id);
        console.log("selectedVipCard:", vipCard);
        console.log("cards:", vipCards);
        console.log(vipCards.filter(c=>c._id === vipCard._id));
        if(wholeVIPObject){
            setSelectedVipCard(wholeVIPObject);
        }else{
            setSelectedVipCard(vipCard)
        }
    };

    const removeVipCard = () => {
        setSelectedVipCard(null);
    };

    return (
        <VipCardContext.Provider value={{ selectedVipCard, selectVipCard, removeVipCard }}>
            {children}
        </VipCardContext.Provider>
    );
};