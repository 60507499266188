export const hungarianRoundNumbers = (price = 0) => {
    let remainder = price % 10;

    if (remainder >= 0.01 && remainder < 2.50) {
        return price - remainder; // Round down to nearest 0
    } else if (remainder >= 2.50 && remainder <= 4.99) {
        return price - remainder + 5; // Round up to nearest 5
    } else if (remainder >= 5.01 && remainder < 7.50) {
        return price - remainder + 5; // Round down to nearest 5
    } else if (remainder >= 7.50 && remainder <= 9.99) {
        return price - remainder + 10; // Round up to nearest 0
    } else {
        return price; // Already a multiple of 10 or 5
    }
    //A törvény a következőképpen határozza meg a kerekítés szabályait:
    //
    // a 0,01 forinttól 2,49 forintig végződő összegeket lefelé, a legközelebbi 0;
    // a 2,50 forinttól 4,99 forintig végződő összegeket felfelé, a legközelebbi 5;
    // az 5,01 forinttól 7,49 forintig végződő összegeket lefelé, a legközelebbi 5;
    // a 7,50 forinttól 9,99 forintig végződő összegeket felfelé, a legközelebbi 0;
    // forintra végződő összegre kell kerekíteni.
};
