import React from "react";
import { Alert, Image, Platform, StatusBar, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import * as LocalAuthentication from 'expo-local-authentication';
import { getAccessToken } from "../helpers/storage";
import LogoImage from "../../assets/icon.png";
import * as SplashScreen from "expo-splash-screen/build/index";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from 'react-i18next';
import '../../i18n';
let tryCount = 0;

const StartScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const [logout, setLogout] = React.useState(true);
    const [turnOff, setTurnOff] = React.useState(true);
    const [isDayOpen, setIsDayOpen] = React.useState(undefined);
    const [token, setToken] = React.useState(undefined);
    const { successDayClose } = route?.params || {};

    React.useEffect(() => {
        const checkLogin = async () => {
            const getToken = await getAccessToken();
            if (getToken) {
                setTurnOff(false);
                setToken(getToken);
                setLogout(false);
            }
            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true') {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }

            SplashScreen.hideAsync();
        };
        checkLogin();
    }, []);

    React.useEffect(() => {
        const checkLogin = async () => {
            const getToken = await getAccessToken();
            if (getToken) {
                setTurnOff(false);
                setToken(getToken);
                setLogout(false);
            }
            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true') {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }

            SplashScreen.hideAsync();
        };
        checkLogin();
    }, [navigation, successDayClose]);

    const authenticate = async () => {
        if (!token) return; // Only authenticate if the user is logged in
        if (Platform.OS === "web") return isDayOpen ? navigation.navigate('HomeScreen') : navigation.navigate('DayOpeningScreen');
        try {
            tryCount++;
            const hasHardware = await LocalAuthentication.hasHardwareAsync();
            if (!hasHardware) {
                return (isDayOpen ? navigation.navigate('HomeScreen') : navigation.navigate('DayOpeningScreen'));
            }

            const isEnrolled = await LocalAuthentication.isEnrolledAsync();
            if (!isEnrolled) {
                return isDayOpen ? navigation.navigate('HomeScreen') : navigation.navigate('DayOpeningScreen');
            }

            const result = await LocalAuthentication.authenticateAsync({
                promptMessage: t("authenticate_prompt"),
                fallbackLabel: t("authenticate_fallback")
            });

            if (result.success) {
                return isDayOpen ? navigation.navigate('HomeScreen') : navigation.navigate('DayOpeningScreen');
            } else {
                authenticate();
            }
        } catch (error) {
            return isDayOpen ? navigation.navigate('HomeScreen') : navigation.navigate('DayOpeningScreen');
        }
    };

    React.useEffect(() => {
        if (logout && token && isDayOpen !== undefined) {
            setTimeout(() => {
                authenticate();
            }, 700);
        }
    }, [navigation, logout, token, isDayOpen]);

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}  // Example gradient colors
            style={styles.background}
        >
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
            <View style={styles.container}>
                {Platform.OS !== "web" && <Image
                    source={LogoImage}
                    style={[styles.logo, { width: 150, height: 150 }]}
                />}
                {Platform.OS === "web" && <img
                    alt={"Happy Solutions Logo"}
                    src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                    style={styles.logo}
                />}
                <Text style={styles.title}>{t("welcome")}</Text>
                <Text style={styles.description}>
                    {t("description")}
                </Text>
                <TouchableOpacity style={styles.loginButton}
                                  onPress={() => logout ? navigation.navigate("LoginScreen") : authenticate()}>
                    <Text style={styles.loginButtonText}>{t("get_started")}</Text>
                </TouchableOpacity>
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    logo: {
        width: 150,
        height: 150,
        marginBottom: 80,
        backgroundColor: 'transparent'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    loginButton: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
    },
    loginButtonText: {
        fontSize: 24,
        color: "white",
        paddingHorizontal: 20,
        textAlign: 'center',
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default StartScreen;