import React, {useState, useEffect, useContext} from "react";
import {
    Modal,
    View,
    Text,
    TextInput,
    FlatList,
    TouchableOpacity,
    StyleSheet, ScrollView
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import {OrdersContext} from "../helpers/OrdersContext";
import {SystemContext} from "../helpers/SystemContext";
import {priceFormatter} from "../helpers";
//import { setOrders } from "../redux/actions/ordersActions";

const PairingModal = ({ visible, onClose, pairingItem, orderId }) => {
    const dispatch = useDispatch();
    const {orders} = useContext(OrdersContext);
    const {foods} = useContext(SystemContext);

    const [searchValue, setSearchValue] = useState("");
    const [filteredFoods, setFilteredFoods] = useState([]);
    const [selectedFood, setSelectedFood] = useState(null);
    const [selectedFoodSize, setSelectedFoodSize] = useState(null);

    useEffect(() => {
        setSearchValue("");
        setSelectedFood(null);
        setSelectedFoodSize(null);
    }, [visible]);

    useEffect(() => {
        console.log("foods", foods);
        const filtered = foods?.filter(food =>
            food.name.hu.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredFoods(filtered);
    }, [searchValue, foods]);

    const handleSave = () => {
        if (!selectedFood || !selectedFoodSize) return;
        const updatedOrders = orders.map(order => {
            if (order._id === orderId) {
                return {
                    ...order,
                    items: order.items.map(item => {
                        if (item.remoteCode === pairingItem.remoteCode) {
                            return {
                                ...item,
                                item: selectedFood,
                                sizeIndex: selectedFoodSize._id,
                                size: selectedFoodSize._id,
                                price: selectedFoodSize.price
                            };
                        }
                        return item;
                    })
                };
            }
            return order;
        });
        //dispatch(setOrders(updatedOrders));
        onClose(false);
    };

    return (
        <Modal visible={visible} transparent animationType="slide">
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{pairingItem?.remoteName}</Text>
                    <TextInput
                        style={styles.searchInput}
                        placeholder="Search food"
                        value={searchValue}
                        onChangeText={setSearchValue}
                    />
                    <ScrollView style={{height: 400}} contentContainerStyle={{height: 400}}>
                        <FlatList
                            data={filteredFoods}
                            keyExtractor={(item) => item._id}
                            renderItem={({ item }) => (
                                <TouchableOpacity
                                    style={[styles.foodItem, selectedFood?._id === item._id && styles.selectedFood]}
                                    onPress={() => setSelectedFood(item)}
                                >
                                    <Text style={styles.foodItemText}>{item.name.hu} - {item.sizes[0]?.amount}{item.sizes[0]?.unit} ({priceFormatter(item.sizes[0]?.price)})</Text>
                                </TouchableOpacity>
                            )}
                        />
                    </ScrollView>

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.cancelButton} onPress={onClose}>
                            <Text style={{fontSize: 22,
                                    fontWeight: "bold"}}>Cancel</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.saveButton, !selectedFood && styles.disabledButton]}
                            onPress={handleSave}
                            disabled={!selectedFood}
                        >
                            <Text style={[selectedFood && {color: "white"},
                                {fontSize: 22,
                                fontWeight: "bold",}]} >Save</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
        justifyContent: "center",
        alignItems: "center",
    },
    modalContent: {
        width: "80%",
        backgroundColor: "white",
        padding: 25,
        borderRadius: 10,
    },
    title: {
        fontSize: 26,
        fontWeight: "bold",
        marginBottom: 10,
        textAlign: "center",
    },
    searchInput: {
        height: 50,
        borderWidth: 1,
        borderColor: "gray",
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    foodItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderColor: "#ddd",
    },
    foodItemText:{
      fontSize: 18,
    },
    selectedFood: {
        backgroundColor: "#f0a500",
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
    },
    cancelButton: {
        paddingVertical: 25,
        paddingHorizontal: 45,
        backgroundColor: "#ccc",
        borderRadius: 5,
    },
    saveButton: {
        paddingVertical: 25,
        paddingHorizontal: 45,
        backgroundColor: "#28a745",
        borderRadius: 5,

    },
    disabledButton: {
        backgroundColor: "#ddd",
    }
});

export default PairingModal;
