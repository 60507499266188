import React from 'react';
import { Modal, TextInput, View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from 'react-i18next';

const CommentModal = ({ visible, onClose, itemName, commentText, setCommentText, handleAddComment, itemId, commentSuccess }) => {
    const { t } = useTranslation();

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={{ marginBottom: 13, fontWeight: 'bold', fontSize: 20 }}>{itemName}</Text>
                    <TextInput
                        style={styles.textInput}
                        placeholder={t("comment_placeholder")}
                        value={commentText}
                        onChangeText={setCommentText}
                    />
                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                        <TouchableOpacity
                            style={[styles.button, styles.cancelButton]}
                            title="Cancel"
                            onPress={() => { onClose(); setCommentText(''); }}
                        >
                            <Text style={styles.buttonText}>{t("close")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.button, styles.saveButton]}
                            title="Add Comment"
                            onPress={() => handleAddComment(itemId, commentText)}
                        >
                            <Text style={styles.buttonText}>{t("save")}</Text>
                        </TouchableOpacity>
                    </View>

                    {commentSuccess !== null && (
                        <View style={{ position: 'absolute', top: 10, left: 0, right: 0, alignItems: 'center' }}>
                            <Text style={{ color: commentSuccess ? 'green' : 'red' }}>
                                {commentSuccess ? t("comment_success") : t("comment_failure")}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalView: {
        margin: 20,
        backgroundColor: '#fff8f1',
        borderRadius: 15,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: Dimensions.get('window').width >= 768 ? '50%' : '85%',
    },
    textInput: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 20,
        marginTop: 20,
        width: '100%',
        paddingHorizontal: 10,
    },
    button: {
        flex: 1,
        padding: 15,
        alignItems: 'center',
        borderRadius: 4,
    },
    cancelButton: {
        backgroundColor: 'grey',
        marginRight: 10,
    },
    saveButton: {
        backgroundColor: '#F76E24',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
});

export default CommentModal;