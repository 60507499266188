import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Animated,
    Dimensions,
    FlatList,
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import LottieView from "lottie-react-native";
import emptyCartNotification from "../../assets/animations/empty_cart.json";
import {LinearGradient} from "expo-linear-gradient";
import {CartContext} from "../helpers/CartContext";
import {priceFormatter} from "../helpers/numbers";
import OnlineOrderProvider, {OnlineOrderContext} from '../helpers/OnlineOrderContext';
//import PaymentModal from './PaymentModal';
import {Gesture, GestureDetector, GestureHandlerRootView, Swipeable} from 'react-native-gesture-handler';
import DiscountModal from "../components/DiscountModal";
import CommentModal from "../components/CommentModal";
import HappyAlert from "../helpers/crossPlatformTools/HappyAlert";
import {runOnJS} from "react-native-reanimated/src";
import {TableContext} from '../helpers/TableContext';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {send_storeOrder} from "../helpers/paymentHelper";
import {OrdersContext} from "../helpers/OrdersContext";
import {useFocusEffect} from "@react-navigation/native";
import PaymentSelector from '../components/PaymentSelector';
import {SystemContext} from "../helpers/SystemContext"; // Adjust the path as necessary
import { useTranslation } from 'react-i18next';
import '../../i18n';
import {RawBTPrintKitchen} from "../helpers/rawBTDriver";
import {VipCardContext} from "../helpers/VipCardContext";

const { width, height } = Dimensions.get('window');
const AUTHORIZED_TO_MODIFY = true;

const CartScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { cartItems, addToCart, cartTotal, removeFromCart, setCart, deleteCart, addComment , productsPrice, discountsForWholeCart, serviceFeeAmount, cartTotalWithServiceFee, alreadySentCartItems, setAlreadySentCartItems, paymentModalVisible, setPaymentModalVisible } = React.useContext(CartContext);
    const [extraIsExpanded, setExtraIsExpanded] = useState(null);
    const { fetchSystemData } = useContext(SystemContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const opacity = useRef(new Animated.Value(1)).current;
    const [isCartExpanded, setIsCartExpanded] = useState(false);
    const [commentModalVisible, setCommentModalVisible] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [commentSuccess, setCommentSuccess] = useState(null);
    const [discountModalVisible, setDiscountModalVisible] = useState(false);
    const [selectedItemPrice, setSelectedItemPrice] = useState(null);
    const [openFrom, setOpenFrom] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { currentTable, removeTable } = React.useContext(TableContext);
    const { fetchOrdersFromAPI, orders } = React.useContext(OrdersContext);
    const [serviceFee, setServiceFee] = useState(0);
    const [isPaymentChoseVisible, setIsPaymentChoseVisible] = useState(false);
    const { orderData, updateOrderData, resetOnlineOrder } = React.useContext(OnlineOrderContext);
    const [editingItemId, setEditingItemId] = useState(null);
    const [inputQuantity, setInputQuantity] = useState('');
    const [combinedCartItems, setCombinedCartItems] = useState( []);
    const {selectedVipCard, removeVipCard} = useContext(VipCardContext);

    useEffect(() => {
        setCombinedCartItems([
            ...alreadySentCartItems.map(item => ({ ...item, canBeModified: AUTHORIZED_TO_MODIFY, hasBackground: true })),
            ...cartItems.map(item => ({ ...item, canBeModified: true })).reverse()
        ]);
    }, [alreadySentCartItems, cartItems]);

    const handleDeleteCart = () => {
        deleteCart();
        setIsConfirmModalVisible(false);
    };

    async function handleOrderSend() {
        let tableOrder;
        if(currentTable){
            tableOrder = orders.find(
                (order) =>
                    !order.storno &&
                    order.status !== "cancelled" &&
                    order.status !== "closed" &&
                    order.tableId === currentTable._id
            );
        }
        try {
              const response = await send_storeOrder(cartTotal, cartItems, "", 0, discountsForWholeCart, undefined, false,
                  currentTable, serviceFeeAmount, true, alreadySentCartItems, tableOrder?._id, [],
                  0, currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal, selectedVipCard);
            if (response) {
                if(cartItems.length > 0) setTimeout(() => {
                    new Promise(() => RawBTPrintKitchen({...response, items: response.items.filter(responseItem => (responseItem.item._id === cartItem.item || responseItem.item === cartItem.item))}, [...response.items.filter(responseItem => (responseItem.item._id === cartItem.item || responseItem.item === cartItem.item))]))
                }, 500);
                removeTable();
                deleteCart()
                setAlreadySentCartItems([]);
                removeVipCard();
                fetchOrdersFromAPI();
                navigation.navigate("TablesScreen");
            }
        } catch (error) {
            console.error("Order sending failed:", error);
        }
    }

    const handleDiscountPress = (itemPrice) => {
        setSelectedItemPrice(itemPrice);
        setDiscountModalVisible(true);
    };

    useEffect(() => {
        if(cartItems?.length === 0 && alreadySentCartItems?.length === 0) {
            setIsCartExpanded(false)
        }
    },[cartItems, alreadySentCartItems]);

    useEffect(() => {
        const fetchServiceFee = async () => {
            try {
                const restaurantPlace = await AsyncStorage.getItem('restaurantPlace');
                if (restaurantPlace) {
                    const  serviceFee  = JSON.parse(restaurantPlace).serviceFee;
                    setServiceFee(serviceFee);
                }
            } catch (error) {
                console.error('Failed to fetch service fee:', error);
            }
        };

        fetchServiceFee();
    }, []);

    const incrementQuantity = (id) => {
        setCart(cartItems.map(item => {
            if (item._id === id) {
                item.quantity += 1;
                const fullPrice = item.price * item.quantity;
                if (item.discounts ) {
                    const discount = item.discounts;
                    if (discount.amount) {
                        item.remotePrice = fullPrice - discount.amount;
                    } else {
                        item.remotePrice = fullPrice - (fullPrice * (discount.percentage / 100));
                    }
                }
            }
            return item;
        }));
        if(alreadySentCartItems){
            console.log(alreadySentCartItems)
            const updatedItems = alreadySentCartItems.map(item => {
                if (item._id === id) {
                    item.amount += 1;
                    const fullPrice = item.price * item.amount;
                    if (item.discounts) {
                        const discount = item.discounts;
                        if (discount.amount) {
                            item.remotePrice = fullPrice - discount.amount;
                        } else {
                            item.remotePrice = fullPrice - (fullPrice * (discount.percentage / 100));
                        }
                    }
                }
                return item;
            });
            setAlreadySentCartItems(updatedItems)
        }
    };

    const decrementQuantity = (id, count = 1) => {
        setCart(cartItems.map(item => {
            if (item._id === id && item.quantity > count) {
                item.quantity -= count;
                const fullPrice = item.price * item.quantity;
                if (item.discounts) {
                    const discount = item.discounts;
                    if (discount.amount) {
                        item.remotePrice = fullPrice - discount.amount;
                    } else {
                        item.remotePrice = fullPrice - (fullPrice * (discount.percentage / 100));
                    }
                }
            } else if (item._id === id && item.quantity <= count) {
                return null; // Remove item if quantity is less than or equal to count
            }
            return item;
        }).filter(item => item !== null));
        if(alreadySentCartItems){
            const updatedItems = alreadySentCartItems.map(item => {
                if (item._id === id && item.amount > count) {
                    item.amount -= 1;
                    const fullPrice = item.price * item.amount;
                    if (item.discounts) {
                        const discount = item.discounts;
                        if (discount.amount) {
                            item.remotePrice = fullPrice - discount.amount;
                        } else {
                            item.remotePrice = fullPrice - (fullPrice * (discount.percentage / 100));
                        }
                    }
                }else if (item._id === id && item.quantity <= count) {
                    return null; // Remove item if quantity is less than or equal to count
                }
                return item;
            });
            setAlreadySentCartItems(updatedItems)
        }
    };

    const handleAddComment = (itemId, commentText) => {
        addComment(itemId, commentText);
        setCommentModalVisible(false);
        setCommentText("");
    };

    const calculateTotal = () => {
        const alreadyInCartPrice = alreadySentCartItems?.length > 0
            ? alreadySentCartItems.reduce((total, item) => total + (item.remotePrice ? item.remotePrice : (item.price * (item.amount || 1))), 0)
            : 0;

        const cartItemsPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

        return cartItemsPrice + alreadyInCartPrice;
    };
    const renderRightActions = (id) => (
        <Animated.View style={[styles.swipeButton, { opacity }]}>
            <Icon name="plus" size={20} color="black" />
        </Animated.View>
    );

    async function handleOrderSubmission(paymentType) {
        try {
            const response = await send_storeOrder(cartTotal, cartItems, paymentType, cartTotal, discountsForWholeCart, undefined, false, currentTable, serviceFeeAmount, false, alreadySentCartItems, "123-432", [], 0, cartTotal, undefined, orderData);
            if (response) {
                deleteCart();
                resetOnlineOrder();
                fetchOrdersFromAPI();
                fetchSystemData();
                navigation.navigate('OnlineScreen');
            } else {
                console.error("Order sending failed:", response);
            }
            setIsPaymentChoseVisible(false);
        } catch (error) {
            console.error("Order sending failed:", error);
        }
    }

    const renderLeftActions = (id) => (
        <Animated.View style={[styles.swipeButton, { opacity }]}>
            <Icon name="minus" size={20} color="black" />
        </Animated.View>
    );

    function handleSwipe(direction, id) {
        direction === 'left' ? decrementQuantity(id) : incrementQuantity(id);
    }

    function swipeClose(swipeable, duration) {
        Animated.timing(opacity, {
            toValue: 0,
            duration: duration || 50,
            useNativeDriver: true,
        }).start(() => {
            swipeable.reset();
            opacity.setValue(1); // Reset opacity for next use
        });
    }

    const handleQuantityPress = (item) => {
        setEditingItemId(item._id);
        setInputQuantity(item?.quantity?.toString() || item?.amount?.toString());
    };

    const handleQuantityConfirm = (id) => {
        const newQuantity = parseInt(inputQuantity, 10);
        if (!isNaN(newQuantity) && newQuantity > 0) {
            setCart(cartItems.map(item => {
                if (item._id === id) {
                    item.quantity = newQuantity;
                }
                return item;
            }));
            if(alreadySentCartItems){
                const updatedItems = alreadySentCartItems.map(item => {
                    if (item._id === id) {
                        item.amount = newQuantity;
                    }
                    return item;
                });
                setAlreadySentCartItems(updatedItems);
            }


        }
        setEditingItemId(null);
    };

    const handleQuantityCancel = () => {
        setEditingItemId(null);
    };

    const renderItem = ({ item, canBeModified, hasBackground }) => {
        const longPressGesture = Gesture.LongPress().onStart(() => {
            if(canBeModified){
                runOnJS(setExtraIsExpanded)(item._id); // Safely call the state updater
                runOnJS(setIsCartExpanded)(false);    // Safely call other state updaters
            }
        })
            .onEnd(() => {
                // Prevent further handling of the gesture
                return true;
            });

        function getSelectedSize(item) {
            const sizeIndex = item.sizeIndex || 0
            return item.sizes && item.sizes[sizeIndex]
        }

        return (
            <GestureDetector gesture={longPressGesture}>
                <Swipeable
                    onSwipeableWillOpen={(direction) => handleSwipe(direction, item._id)}
                    onSwipeableOpen={(direction, swipeable) => swipeClose(swipeable)}
                    renderRightActions={() => canBeModified && renderRightActions(item._id)}
                    renderLeftActions={() => canBeModified && renderLeftActions(item._id)}
                >
                    <View style={[styles.itemContainer, extraIsExpanded === item._id && styles.selectedItemContainer, {backgroundColor: hasBackground ? `#ccc` :`transparent`}]}>
                        <View style={{ flex: 5 }}>
                            <Text style={styles.itemName}>{item.name.hu || item.name}</Text>
                            <Text style={styles.unitPrice}>{getSelectedSize(item)?.amount || "1"} {getSelectedSize(item)?.unit || "db"} / {priceFormatter(item.price)}</Text>
                            {item?.comments?.length > 0 && <Text style={styles.smallComment}>"{item?.comments?.join('", "')}"</Text>}
                            {item?.extras?.length > 0 && item.extras.map((extra, index) => (
                                <Text key={index} style={styles.smallComment}>+ {extra?.item?.name?.hu || extra?.item?.name} - {priceFormatter(extra.price || extra?.item?.sizes[0]?.price)}</Text>
                            ))}
                        </View>
                        {canBeModified && <View style={[styles.quantityContainer, {flex: 2, textAlign: 'center', alignItems: 'center'}]}>
                            {canBeModified && <TouchableOpacity style={{marginRight: "8"}}
                                                                onPress={() => decrementQuantity(item._id)}>
                                <Icon name="minus" size={20}/>
                            </TouchableOpacity>}
                            {editingItemId === item._id ? (
                                <TextInput
                                    style={styles.quantityInput}
                                    value={inputQuantity}
                                    onChangeText={(text) => setInputQuantity(text.replace(/[^0-9]/g, ''))}
                                    keyboardType="numeric"
                                    autoFocus
                                    onBlur={() => handleQuantityCancel()}
                                    onSubmitEditing={() => handleQuantityConfirm(item._id)}
                                />
                            ) : (
                                <TouchableOpacity onPress={() => handleQuantityPress(item)}>
                                    <Text style={styles.quantityText}>{item.quantity || item.amount}</Text>
                                </TouchableOpacity>
                            )}
                            {canBeModified &&
                                <TouchableOpacity style={{marginLeft: "8"}} onPress={() => incrementQuantity(item._id)}>
                                    <Icon name="plus" size={20}/>
                                </TouchableOpacity>}
                        </View>}
                        {!item.remotePrice || item?.remotePrice === item.price ? (<Text style={[styles.itemPrice, { flex: 2, alignItems: "flex-end", textAlign: 'right' }]}>{priceFormatter(Number(item.price) * (Number(item.quantity) || Number(item.amount)))}</Text>
                        ):(<View style={{marginLeft: 8}}>
                            <Text style={[styles.itemName, { flex: 2, alignItems: "flex-end", textAlign: 'right', textDecorationLine: "line-through" }]}>
                                {priceFormatter(Number(item.price) * (Number(item.quantity) || Number(item.amount)))}
                            </Text>
                            <Text style={styles.unitPrice}>{priceFormatter(item.remotePrice)}</Text></View>)}

                    </View>
                    {extraIsExpanded === item._id && (
                        <Animated.View style={[styles.extraRow, extraIsExpanded === item._id && styles.selectedExtraRow]}>
                            <TouchableOpacity style={[styles.chevronButton]} onPress={() => setExtraIsExpanded(false)}>
                                <Icon name="chevron-up" size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.extraButton, styles.deleteButton]} onPress={() => removeFromCart(item._id)}>
                                <Icon name="trash-2" color="white" size={26} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.extraButton, styles.discountButton]} onPress={() => {
                                setOpenFrom("cartItem");
                                handleDiscountPress(item.price * item.quantity, item.remotePrice);
                            }}>
                                <Icon name="percent" color="white" size={26} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.extraButton, styles.commentButton]} onPress={() => {
                                setCommentModalVisible(true);
                                setSelectedRow(item);
                            }}>
                                <Icon name="message-square" color="white" size={26} />
                            </TouchableOpacity>
                        </Animated.View>
                    )}
                </Swipeable>
            </GestureDetector>
        );
    };

    const handlePaymentPress = () => {
        //console.log(cartItems.length === 0 && alreadySentCartItems?.length === 0)
        //Online order
        console.log(orderData)
        if (cartItems.length === 0 && alreadySentCartItems?.length === 0) return;
        if (orderData?.orderType && orderData?.orderType?.length > 0) {
            setIsPaymentChoseVisible(true);
        }
        else{

            navigation.navigate("PaymentScreen");
            // if (width >= 768) {
            //     setPaymentModalVisible(true);
            // } else {
            //     navigation.navigate("PaymentScreen");
            // }
        }
    };

    return (
        <GestureHandlerRootView style={{ flex: 1 }}>

            <LinearGradient
                colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                style={{ flex: 1, width: '100%', height: '100%' }}
            >
                <View style={styles.container}>
                    {currentTable && (
                        <TouchableOpacity
                            style={styles.tableNameContainer}
                            onPress={() => {
                                removeTable();
                                navigation.navigate("TablesScreen");
                            }}
                        >
                            <Text style={styles.tableNameText}>{t("table")}: {currentTable.name}</Text>
                        </TouchableOpacity>
                    )}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <>{(alreadySentCartItems?.length > 0 && combinedCartItems?.length > 0) ? <FlatList
                            data={combinedCartItems}
                            style={{ flex: 1 }}
                            renderItem={({ item }) => renderItem({ item, canBeModified: item.canBeModified, hasBackground: item.hasBackground })}
                            keyExtractor={(item, index) => `${item.id}-${index}`}
                        /> :
                        cartItems.length > 0 && <FlatList
                            data={[...cartItems].reverse()}
                            renderItem={({ item }) => renderItem({ item, canBeModified: true })}
                            keyExtractor={(item, index) => `${item.id}-${index}`}
                        />}</>}
                    {cartItems.length === 0 && alreadySentCartItems?.length === 0 && <View style={{
                        flex: 1,
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        <View style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 120
                        }}>
                            <LottieView
                                source={emptyCartNotification}
                                autoPlay
                                loop={true}
                                style={styles.lottie}
                                renderMode={'AUTOMATIC'}
                                resizeMode={'center'}
                                enableSafeModeAndroid={true}
                            />
                            <Text style={{ fontSize: 22, color: 'black', marginTop: 20 }}>
                                {t("cart_empty")}
                            </Text>
                        </View>
                    </View>}
                    {isCartExpanded && (
                        <View style={[styles.totalContainer, styles.brighterBackground]}>
                            <View style={[styles.brighterBackground, { flexDirection: 'row', alignItems: 'center' }]}>
                                <TouchableOpacity onPress={() => setIsConfirmModalVisible(true)} style={[styles.extraButton, styles.deleteButton]}>
                                    <Icon name="trash-2" color="#ccc" size={20} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[styles.extraButton, styles.discountButton]}
                                    onPress={() => {
                                        setOpenFrom("cartTotal");
                                        setSelectedItemPrice(undefined);
                                        setDiscountModalVisible(true);
                                    }}
                                >
                                    <Icon name="percent" color="#eee" size={20} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && (
                        <TouchableOpacity
                            onPress={() => {
                                if (calculateTotal() > 0) {
                                    setExtraIsExpanded(null);
                                    setIsCartExpanded(!isCartExpanded);
                                }
                            }}
                        >
                            <View style={[styles.detailsContainer, isCartExpanded && styles.brighterBackground, { flexDirection: 'row', alignItems: 'center', borderTopWidth: 1, borderTopColor: '#ccc' }]}>
                                <Text style={styles.productSumText}> {t("products_total")}</Text>
                                <Text style={styles.productSumText}>{priceFormatter(productsPrice)} </Text>
                            </View>

                            {discountsForWholeCart && (discountsForWholeCart?.amount > 0 || discountsForWholeCart?.percentage > 0) && (
                                <View style={[styles.detailsContainer, isCartExpanded && styles.brighterBackground, { flexDirection: 'row', alignItems: 'center' }]}>
                                    <Text style={styles.detailsText}>
                                        {discountsForWholeCart.amount > 0 ? ` -${priceFormatter(discountsForWholeCart.amount)} :` : ` (${discountsForWholeCart.percentage}%):`}
                                    </Text>
                                    <Text style={styles.detailsText}>
                                        {discountsForWholeCart.amount > 0
                                            ? ` -${priceFormatter(discountsForWholeCart.amount)}`
                                            : ` -${priceFormatter(productsPrice * (discountsForWholeCart?.percentage / 100))} `}
                                    </Text>
                                </View>
                            )}

                            {currentTable && (
                                <View style={[styles.detailsContainer, isCartExpanded && styles.brighterBackground, { flexDirection: 'row', alignItems: 'center' }]}>
                                    <Text style={styles.detailsText}>{t("service_fee")}({serviceFee}%):</Text>
                                    <Text style={styles.detailsText}>{serviceFeeAmount.toFixed(1).replace(/\.0$/, '')} Ft </Text>
                                </View>
                            )}

                            <View style={[styles.totalContainer, isCartExpanded && styles.brighterBackground, { flexDirection: 'row', alignItems: 'center' }]}>
                                <Text style={styles.totalText}>{t("payable")}:</Text>
                                <Icon name={isCartExpanded ? "chevron-down" : "chevron-up"} color="#fffff" size={20} />
                                <Text style={styles.totalText}>{priceFormatter(currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal)}</Text>
                            </View>
                        </TouchableOpacity>
                    )}
                    {(cartItems?.length > 0 || alreadySentCartItems?.length > 0) && (
                        <View style={[styles.totalContainer, { fontSize: 40, padding: 5, flexDirection: 'row', justifyContent: 'space-between' }]}>
                            <TouchableOpacity
                                style={[!currentTable ? styles.processButton : styles.saveTableButton, { flex: 1, marginRight: 1 }]}
                                disabled={cartItems?.length === 0 && alreadySentCartItems?.length === 0}
                                onPress={handlePaymentPress}
                            >
                                <Text style={!currentTable ? styles.processButtonText : styles.saveTableText}>{t("payment")}</Text>
                            </TouchableOpacity>
                            {currentTable && (
                                <TouchableOpacity style={[styles.processButton, { flex: 1, marginLeft: 1 }]} onPress={handleOrderSend}>
                                    <Text style={styles.processButtonText}>{t("send")}</Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    )}
                    {discountModalVisible && <DiscountModal
                        visible={discountModalVisible}
                        onClose={() => setDiscountModalVisible(false)}
                        totalPrice={calculateTotal()}
                        itemPrice={selectedItemPrice}
                        selectedCartItemID={extraIsExpanded}
                        openFrom={openFrom}
                    />}
                    {commentModalVisible && <CommentModal
                        visible={commentModalVisible}
                        onClose={() => setCommentModalVisible(false)}
                        itemName={selectedRow?.name.hu || selectedRow?.name}
                        commentText={commentText}
                        setCommentText={setCommentText}
                        handleAddComment={handleAddComment}
                        itemId={selectedRow?._id}
                        commentSuccess={commentSuccess}
                    />}
                    {isConfirmModalVisible && (
                        <Modal animationType="slide" transparent={true} visible={isConfirmModalVisible} onRequestClose={() => setIsConfirmModalVisible(false)}>
                            <HappyAlert
                                visible={isConfirmModalVisible}
                                onConfirm={handleDeleteCart}
                                onCancel={() => setIsConfirmModalVisible(false)}
                                type={"warning"}
                                message={t("delete_cart_confirm")}
                                confirmText={t("yes")}
                                cancelText={t("no")}
                            />
                        </Modal>
                    )}
                    {isPaymentChoseVisible && (
                    <Modal
                        visible={isPaymentChoseVisible}
                        transparent={true}
                        animationType="slide"
                        onRequestClose={() => setIsPaymentChoseVisible(false)}
                    >
                        <TouchableOpacity style={styles.modalOverlay} onPress={() => setIsPaymentChoseVisible(false)}>
                            <View style={styles.modalContent}>
                                <PaymentSelector onSelect={handleOrderSubmission} />
                            </View>
                        </TouchableOpacity>
                    </Modal>
                )}

                </View>
            </LinearGradient>
        </GestureHandlerRootView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        paddingTop: 10,
        zIndex: 999
    },
    processButton: {
        backgroundColor: '#F76E24',
        alignItems: 'center',
        padding: 7,
        paddingVertical: 15,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    saveTableButton: {
        backgroundColor: '#fff9eb',
        alignItems: 'center',
        padding: 7,
        paddingVertical: 15,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    confirmButton: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#4CAF50',
        borderRadius: 5,
    },
    confirmButtonText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    processButtonText: {
        fontSize: 20,
        color: '#ececec',
    },
    saveTableText: {
        fontSize: 20,
        color: '#F76E24',
    },
    unitPrice: {
        fontSize: 12,
        color: '#888',
    },
    modalOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    modalContent: {
        width: width >= 768 ? '60%' : '75%',
        maxHeight: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#f76e24',
        borderRadius: 5,
    },
    closeButtonText: {
        color: 'white',
        fontSize: 16,
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    selectedItemContainer: {
        backgroundColor: 'rgba(255,245,189,0.67)',
    },
    itemName: {
        fontSize: 16,
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    quantityText: {
        marginHorizontal: 8,
        fontSize: 16,
        fontWeight: "700"
    },
    itemPrice: {
        fontSize: 16,
    },
    totalContainer: {
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        justifyContent: 'space-between',
    },
    detailsContainer: {
        padding: 2,
        justifyContent: 'space-between',
    },
    detailsText: {
        fontSize: 16,
        marginBottom: 5,
    },
    productSumText: {
        fontSize: 18,
        marginBottom: 3
    },
    brighterBackground: {
        backgroundColor: 'rgba(255,246,207,0.67)', // Brighter background color
    },
    totalText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    animationContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    lottie: {
        width: Dimensions.get("screen").width - 32,
        height: Dimensions.get("screen").width -32,
    },
    background: {
        flex: 1,
        background: '#393939',
        width: '100%',
        height: height - 90
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
    swipeButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        backgroundColor: 'transparent',
    },
    extraRow: {
        flexDirection: 'row',
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    selectedExtraRow: {
        backgroundColor: 'rgba(255,245,189,0.67)',
    },
    extraButton: {
        flex: 1,
        padding: 14,
        backgroundColor: '#ddd',
        borderRadius: 4,
        marginHorizontal: 4,
        alignItems: 'center',
    },
    deleteButton: {
        backgroundColor: '#7a0202',
        color: '#ddd',
    },
    discountButton: {
        backgroundColor: '#F76E24',
        color: '#ddd',
    },
    commentButton: {
        backgroundColor: '#50A5F1',
        color: '#ddd',
    },
    chevronButton: {
        flex: 0.4,
        padding: 10,
        backgroundColor: '#eee',
        borderRadius: 4,
        marginHorizontal: 4,
        alignItems: 'center',
    },
    modalView: {
        margin: 15,
        backgroundColor: '#fff8f1',
        borderRadius: 15,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    textInput: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 20,
        width: '100%',
        paddingHorizontal: 10,
    },
    scrollView: {
        maxHeight: 200, // Set the maximum height for the scrollable area
    },
    scrollViewContent: {
        paddingBottom: 10,
    },
    discountContainer: {
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
    },
    discountTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    discountText: {
        fontSize: 14,
        color: '#333',
    },
    itemDiscountContainer: {
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
    },
    originalSumContainer: {
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        marginTop: 10,
    },
    quantityInput: {
        height: 30,
        minWidth: 30,
        maxWidth: 60,
        borderColor: 'gray',
        borderWidth: 1,
        paddingHorizontal: 2,
        borderRadius: 4,
        textAlign: 'center',
    },
    originalSumText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
    },
    tableNameContainer: {
        padding: 6,
        backgroundColor: '#f1b44c',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        alignItems: 'center',
        borderRadius: 5,
    },
    tableNameText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff3e9',
    },
});

export default CartScreen;