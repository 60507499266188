import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { priceFormatter } from "../helpers";
import {useTranslation} from "react-i18next";

const OrderRow = ({ order, onPress }) => {
    const { t } = useTranslation();

    const getBackgroundColor = () => {
        if (order.storno || order.status === 'cancelled') {
            return styles.stornoBackground;
        } else if (order.status === 'closed' && !order.storno) {
            return styles.closedBackground;
        } else if (order.preOrder) {
            return styles.preOrderBackground;
        }
        return styles.defaultBackground;
    };

    const convertPaymentType = (paymentType) => {
        switch (paymentType) {
            case "cash":
                return t("cash");
            case "creditCard":
                return t("credit_card");
            case "voucher":
                return t("voucher");
            case "vipBalance":
                return t("vip_balance");
            default:
                return `${paymentType}`;
        }
    };

    const getOrderIcon = (source) => {
        switch (source) {
            case 'terminal':
                return <MaterialIcons name="point-of-sale" size={24} color="#555" />;
            case 'phone':
                return <FontAwesome5 name="mobile-alt" size={24} color="#555" />;
            case 'webshop':
                return <MaterialIcons name="web" size={24} color="#555" />;
            default:
                return <MaterialIcons name="point-of-sale" size={24} color="#555" />;
        }
    };

    return (
        <TouchableOpacity onPress={() => onPress(order)} style={[styles.orderRow, getBackgroundColor()]}>
            <View style={styles.leftSection}>
                <Text style={styles.orderDate}>{new Date(order.createdAt).toLocaleTimeString()}</Text>
                {order.preOrder && <Text style={styles.preOrderText}>{new Date(order.preOrder).toLocaleString()}</Text>}
            </View>
            <View style={styles.orderDetails}>
                {getOrderIcon(order.orderFrom)}
                <Text style={styles.orderOrigin}>{order.orderFrom === order.restaurantPlace ? "terminal" : order.orderFrom}</Text>
            </View>
            <View style={[styles.orderDetails, { marginLeft: "5px" }]}>
                {order?.tableId && (
                    <View>
                        <MaterialIcons name="table-bar" size={24} color="#555" />
                        <Text style={styles.orderOrigin}>{order?.tableName}</Text>
                    </View>
                )}
            </View>
            <View style={styles.middleSection}>
                <Text style={styles.orderCode}>#{order.orderCode}</Text>
            </View>
            <View style={styles.userInfo}>
                {order?.userInfo && (
                    <View>
                        <View style={styles.personalInfo}>
                            <Text style={{ flex: 1 }}>{order?.userInfo?.name?.first} {order?.userInfo?.name?.last} ({order?.userInfo?.phone})</Text>
                        </View>
                        {order.addressInfo && (
                            <View style={styles.addressInfo}>
                                <Text style={{ flex: 1 }}>{order?.addressInfo?.zip} {order?.addressInfo?.city}, {order?.addressInfo?.address}</Text>
                            </View>
                        )}
                    </View>
                )}
            </View>
            <View style={styles.rightSection}>
                <Text style={styles.paymentType}>{convertPaymentType(order.paymentType)}</Text>
                <Text style={styles.orderAmount}>{priceFormatter(order.orderSum)}</Text>
                <Text style={styles.itemCount}>{order.items.reduce((sum, item) => sum + item.amount, 0)} {t("item-s")}</Text>
            </View>
            <View style={styles.statusIndicator}>
                {/* Add status indicators if needed */}
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    orderRow: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        borderRadius: 8,
        marginVertical: 6,
        borderWidth: 1,
        borderColor: '#ccc',
    },
    leftSection: {
        flex: 1,
        alignItems: 'flex-start',
    },
    middleSection: {
        flex: 2,
        alignItems: 'center',
    },
    rightSection: {
        flex: 1,
        alignItems: 'flex-end',
    },
    orderDetails: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1,
    },
    userInfo: {
        flex: 3,
        justifyContent: 'center',
    },
    orderAmount: {
        textAlign: 'right',
    },
    orderDate: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    orderCode: {
        flex: 1,
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 5,
    },
    orderOrigin: {
        flex: 1,
        fontSize: 14,
        color: '#555',
    },
    itemCount: {
        fontSize: 14,
        color: '#777',
    },
    paymentType: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#555',
    },
    stornoBackground: {
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
    },
    statusIndicator: {
        marginLeft: 10,
    },
    closedBackground: {
        backgroundColor: 'rgba(214,255,180,0.3)',
    },
    preOrderBackground: {
        backgroundColor: 'rgba(255, 165, 0, 0.5)',
    },
    defaultBackground: {
        backgroundColor: '#f5f5f5',
    },
});

export default OrderRow;