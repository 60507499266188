import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import AntDesign from '@expo/vector-icons/AntDesign';
import LottieView from 'lottie-react-native';

const { width, height } = Dimensions.get('window');

const BookingResultModal = ({ visible, bookingResult, onClose }) => {
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                setShowError(true);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [visible]);

    if (!visible) return null;

    return (
        <View style={styles.backdrop}>
            <View
                colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
                style={styles.modal}
            >
                <TouchableOpacity style={styles.closeButton} onPress={() => onClose(false)}>
                    <AntDesign name="closecircle" size={24} color="black" />
                </TouchableOpacity>
                <View style={styles.content}>
                    {showError ? (
                        <>
                            <AntDesign name="exclamationcircle" size={68} color="red" style={{ paddingBottom: 30 }} />
                            <Text style={styles.errorText}>{t('save_failed')}</Text>
                            <Text style={styles.errorSubText}>{t('try_again')}</Text>
                        </>
                    ) : (
                        <LottieView source={require('../../assets/animations/loading-circle-orange.json')} autoPlay loop style={styles.spinner} />
                    )}
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0, bottom: 0,
        width: '100%', height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: 'white',
        position: 'fixed',
        top: (height / 2) - 130,
        left: (width / 2) - 210,
        width: 420,
        padding: 20,
        height: 260,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    spinner: {
        width: 60,
        height: 60,
    },
    errorText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'red',
        textAlign: 'center',
    },
    errorSubText: {
        fontSize: 16,
        color: 'black',
        textAlign: 'center',
        marginTop: 10,
    },
});

export default BookingResultModal;