import AsyncStorage from "@react-native-async-storage/async-storage";
import {Alert, Platform} from "react-native";
import axios from 'axios';
import {sendPrinterAndroid} from "./printerLibrary/printer";
import {getBigReceiptData, getBigTerminalData} from "./printerLibrary/oldPrinter";
import {getUserInfo} from "./storage";

const API_URL = "https://api.happygastro.hu/api";
const generateRandomNumberString = (length = 10) => {
    let result = '';
    const characters = '0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const fetchBarcodeAsBase64 = async (value) => {
    const url = `${API_URL}/tickets/barcode/${value}`;

    try {
        const response = await axios.get(url, {responseType: 'json'});
        return response.data.barcode || response.barcode;
    } catch (error) {
        console.error('Error fetching barcode:', error);
    }
};

export function RawBTPrintKitchen(order, items) {
    const printTicket = async () => {

        if (Platform.OS !== 'android') return;
        const userInfo = await getUserInfo();



        const restaurantData = await AsyncStorage.getItem("restaurant");
        try {
            const storedPrinterEvents = await AsyncStorage.getItem('printerEvents');
            const printerEvents = storedPrinterEvents ? JSON.parse(storedPrinterEvents) : [];
            if (printerEvents.length > 0) {
                const terminalEvents = printerEvents.filter(event => event.type === "terminal");
                terminalEvents.forEach((event, eventIndex) => {
                    if (event.printers && event.printers.length > 0) {
                        event.printers.forEach((printer, printerIndex) => {
                            const printerDetails = printer;
                            const timeout = (eventIndex * 50) + (printerIndex * 50);

                            // Filter items based on event.products
                            const filteredItems = items.filter(item => event?.products?.includes(item.itemId || item.item._id || item.item));

                            if(!filteredItems || filteredItems.length === 0) {
                                console.log("No items for this printer event", {name: event.name, products: filteredItems.length});
                                return false;
                            }

                            if (printerDetails.ip) {
                                new Promise((resolve, reject) => {
                                    setTimeout(() => sendPrinterAndroid(getBigTerminalData({
                                        ...order,
                                        items: filteredItems
                                    }, JSON.parse(restaurantData), userInfo), {
                                        ip: printerDetails.ip,
                                        port: Number(printerDetails.port),
                                        timeout: 400,autoCut: true,
                                        mmFeedPaper: 230,
                                        printerDpi: 203,
                                        printerWidthMM: 80,
                                        printerNbrCharactersPerLine: 48,
                                    }), timeout);
                                });
                            } else if ((printerDetails.address || printerDetails.macAddress)) {
                                new Promise((resolve, reject) => {
                                    return setTimeout(() => sendPrinterAndroid(getBigTerminalData({
                                        ...order,
                                        items: filteredItems
                                    }, JSON.parse(restaurantData), userInfo), {
                                        macAddress: (printerDetails.address || printerDetails.macAddress),
                                    }));
                                });
                            }
                        });
                    } else {
                        console.log("No items for this printer event", event.name);
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    try {
        new Promise(() => printTicket());
    } catch (e) {
        console.error(e);
        Alert.alert("Hiba a nyomtatás közben:", e);
    }
}

export function RawBTPrintTicket(order, items) {
    const printTicket = async () => {

        if (Platform.OS !== 'android') return;
        const userInfo = await getUserInfo();
        const printerDevice = await AsyncStorage.getItem('printerDevice');
        const restaurantData = await AsyncStorage.getItem("restaurant");
        const restaurantPlaceData = await AsyncStorage.getItem("restaurantPlace");
        let printerData = printerDevice ? JSON.parse(printerDevice) : null;

        console.log("printerDevice", printerDevice);
        if (printerData && (printerData.address || printerData.macAddress)) new Promise(() => sendPrinterAndroid(getBigReceiptData(order, JSON.parse(restaurantData), JSON.parse(restaurantPlaceData), userInfo), {
            ...printerData,
            macAddress: (printerData.address || printerData.macAddress)
        }));

        const storedPrinterEvents = await AsyncStorage.getItem('printerEvents');
        const printerEvents = storedPrinterEvents ? JSON.parse(storedPrinterEvents) : [];

        if (printerEvents.length > 0) {
            const event = printerEvents.find(event => event.type === "receipt");
            if (event && event.printers && event.printers.length > 0) {
                event.printers.forEach((printer, index) => {
                    const printerDetails = printer;
                    if (printer.ip) {
                        console.log("Printing invoice to:", printerDetails)
                        return setTimeout(() => sendPrinterAndroid(getBigReceiptData(order, JSON.parse(restaurantData), JSON.parse(restaurantPlaceData), userInfo), {
                            ip: printerDetails.ip, //ip: ipAddress,
                            port: Number(printerDetails.port),
                            timeout: 400,
                            autoCut: true,
                            mmFeedPaper: 230, // TODO: Feed paper
                            printerDpi: 203,
                            printerWidthMM: 76,
                            printerNbrCharactersPerLine: 48,
                        }), (index * 100));
                    } else {
                        console.log("Printing invoice to:", printerDetails)
                        return setTimeout(() => sendPrinterAndroid(getBigReceiptData(order, JSON.parse(restaurantData), JSON.parse(restaurantPlaceData), userInfo), {
                            macAddress: (printerDetails.address || printerDetails.macAddress)
                        }), (index * 100))
                    }
                });
            }
        }


        return true;
    }

    try {
        new Promise(() => printTicket());
    } catch (e) {
        console.error(e);
        Alert.alert("Hiba a nyomtatás közben:", e);
    }
}