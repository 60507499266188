import React, { useState, useContext, useRef } from 'react';
import {
    Animated,
    Dimensions,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    View,
    Text,
    FlatList,
    Modal,
    Pressable,
    Button,
    ScrollView
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import IonIcons from 'react-native-vector-icons/Ionicons';
import { useNavigation } from '@react-navigation/native';
import { SystemContext } from './SystemContext';
import { VipCardContext } from './VipCardContext';
import { getAccessToken } from "../helpers/storage";
import { Checkbox } from "react-native-paper";
import { useTranslation } from 'react-i18next';
import '../../i18n';

const generateCardId = () => {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100) + "-" + Math.floor(Math.random() * (999 - 100 + 1) + 100);
};

const CustomHeader = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const [vipMenuVisible, setVipMenuVisible] = useState(false);
    const [addVipModalVisible, setAddVipModalVisible] = useState(false);
    const [filterText, setFilterText] = useState('');
    const { vipCards, fetchSystemData } = useContext(SystemContext);
    const { selectedVipCard, selectVipCard, removeVipCard } = useContext(VipCardContext);
    const iconRef = useRef(null);
    const inputRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const [cardId, setCardId] = useState(generateCardId());
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [creditPercentage, setCreditPercentage] = useState('');
    const [searchInputValue, setSearchInputValue] = useState(props.searchString);
    const [serviceFee, setServiceFee] = useState(false);
    const [isCardInputVisible, setIsCardInputVisible] = useState(false);

    const openVipMenu = () => {
        if (iconRef.current) {
            iconRef.current.measure((fx, fy, width, height, px, py) => {
                setMenuPosition({ top: py, left: px - 230 });
                setVipMenuVisible(true);
            });
        }
    };

    const handleVipSelect = (vipCard) => {
        console.log(vipCard)
        if (selectedVipCard && selectedVipCard._id === vipCard._id) {
            removeVipCard();
        } else {
            selectVipCard(vipCard);
        }
        setVipMenuVisible(false);
    };

    const renderVipItem = ({ item }) => {
        const isSelected = selectedVipCard && selectedVipCard._id === item._id;
        const formattedBalance = new Intl.NumberFormat('hu-HU').format(item.balance) + ' Ft';
        const discountText = item.discountAmount ? `${item.discountAmount}% ${t("discount_title")}` : '';

        return (
            <TouchableOpacity
                onPress={() => handleVipSelect(item)}
                style={[styles.vipItem, isSelected && styles.selectedVipItem]}
            >
                <IonIcons name="person-circle-outline" size={35} style={styles.vipIcon} />
                <View style={styles.vipInfo}>
                    <Text style={styles.vipName}>
                        {item.user.name.first && item.user.name.last
                            ? `${item.user.name.first} ${item.user.name.last}`
                            : item.cardId}
                    </Text>
                    <Text style={styles.vipBalance}>{t("balance")}: {formattedBalance}</Text>
                    <Text style={styles.vipDiscount}>{discountText}</Text>
                </View>
                <IonIcons name="information-circle-outline" size={25} style={{
                    marginLeft: 12,
                    padding: 8,
                    backgroundColor: '#add1ff',
                    borderRadius: 6,
                    color: '#007AFF'}} onPress={() => { setVipMenuVisible(false); navigation.navigate('VipUserDetailScreen', { vipCard: item }); }} />
            </TouchableOpacity>
        );
    };

    const filteredVipCards = vipCards.filter((card) => {
        const fullName = `${card.user.name.first} ${card.user.name.last}`.toLowerCase();
        return fullName.includes(filterText.toLowerCase());
    });

    const sortedVipCards = selectedVipCard
        ? [selectedVipCard, ...filteredVipCards.filter((card) => card._id !== selectedVipCard._id)]
        : filteredVipCards;

    const handleSave = async () => {
        const token = await getAccessToken();
        const vipUserData = {
            cardId: cardId,
            startAmount: 0,
            creditPercentage: Number(creditPercentage || 0),
            discountAmount: Number(discountPercentage || 0),
            enableServiceFee: serviceFee || true,
            user: {
                name: {
                    first: firstName,
                    last: lastName,
                },
                phone: phoneNumber,
            },
        };

        try {
            const response = await fetch('https://api.happygastro.hu/api/vipcard/register', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify(vipUserData),
            });

            if (!response.ok) {
                throw new Error('Failed to save VIP user');
            }

            const data = await response.json();
            await fetchSystemData();
            setAddVipModalVisible(false);
        } catch (error) {
            console.error('Error saving VIP user:', error);
        }
    };

    const toggleCardInputVisibility = () => {
        setIsCardInputVisible(!isCardInputVisible);
        if (!isCardInputVisible) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 100);
        }
    };

    return (
        <View style={[styles.header]}>
            <TouchableOpacity onPress={() => navigation.navigate('TicketEntry')} activeOpacity={0.9}>
                <Icon name="ticket" size={30} style={styles.icon} />
            </TouchableOpacity>

            <View style={styles.searchContainer}>
                {false && <Icon name="search" size={20} style={styles.searchIcon}/>}
                <TextInput
                    ref={inputRef}
                    style={[styles.searchInput, { color: props.searchString.length > 0 ? '#414040' : '#575757' }]}
                    placeholder={t('search_placeholder')}
                    value={searchInputValue}
                    onChangeText={(text) => {
                        setSearchInputValue(text)
                        props.setSearchString(text)
                    }}
                    onFocus={() => setSearchInputValue('')}
                />
            </View>

            <TouchableOpacity ref={iconRef} activeOpacity={0.6} onPress={openVipMenu}>
                <IonIcons
                    name="people-circle-outline"
                    size={30}
                    style={[styles.icon, selectedVipCard && { color: '#F1B44C' }]}
                />
            </TouchableOpacity>

            <Modal
                transparent
                visible={vipMenuVisible}
                animationType="fade"
                onRequestClose={() => setVipMenuVisible(false)}
            >
                <Pressable style={styles.modalOverlay} onPress={() => setVipMenuVisible(false)} />
                <View style={[styles.vipMenuContent, { top: menuPosition.top, left: menuPosition.left }]}>
                    <TextInput
                        style={styles.filterInput}
                        placeholder={t('search_placeholder')}
                        value={filterText}
                        onChangeText={setFilterText}
                        onFocus={() => setFilterText('')}
                    />
                    <FlatList
                        data={sortedVipCards}
                        renderItem={renderVipItem}
                        keyExtractor={(item) => item._id}
                        style={styles.vipList}
                    />

                    <TouchableOpacity
                        style={styles.modalOption}
                        onPress={() => {
                            setVipMenuVisible(false);
                            navigation.navigate('VipUserScreen');
                        }}
                    >
                        <Text>{t('all_vip')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.modalOption, { backgroundColor: 'orange' }]}
                        onPress={() => {
                            setVipMenuVisible(false);
                            setAddVipModalVisible(true);
                        }}
                    >
                        <Text style={{ color: 'white', fontWeight: "bold" }}>{t('add_vip')}</Text>
                    </TouchableOpacity>
                </View>
            </Modal>

            <Modal
                transparent
                visible={addVipModalVisible}
                animationType="slide"
                onRequestClose={() => setAddVipModalVisible(false)}
            >
                <View style={styles.modalOverlay}>
                    <View style={styles.addVipModalContent}>
                        <ScrollView contentContainerStyle={styles.container}>
                            <Text style={styles.label}>{t('card')}</Text>
                            <View style={{flexDirection: "row"}}>
                                <TouchableOpacity onPress={toggleCardInputVisibility} activeOpacity={0.6}>
                                    <IonIcons name="qr-code-outline" size={30} style={styles.icon} />
                                </TouchableOpacity>

                                {isCardInputVisible && (
                                    <>
                                        <TextInput
                                            ref={inputRef}
                                            style={styles.input}
                                            value={cardId}
                                            onChangeText={setCardId}
                                            onFocus={(e) => {e.target.value = ''}}
                                        />
                                    </>
                                )}
                            </View>

                            <View style={styles.row}>
                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>Vezetéknév</Text>
                                    <TextInput style={styles.input} value={lastName} onChangeText={setLastName} />
                                </View>
                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>Keresztnév</Text>
                                    <TextInput style={styles.input} value={firstName} onChangeText={setFirstName} />
                                </View>
                            </View>

                            <Text style={styles.label}>{t('phone_number')}</Text>
                            <TextInput style={styles.input} value={phoneNumber} onChangeText={setPhoneNumber} keyboardType="phone-pad" />

                            <Text style={styles.label}>{t('email')}</Text>
                            <TextInput style={styles.input} value={email} onChangeText={setEmail} keyboardType="email-address" />

                            <View style={styles.row}>
                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>{t('last_name')}</Text>
                                    <TextInput style={styles.input} value={lastName} onChangeText={setLastName} />
                                </View>
                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>{t('first_name')}</Text>
                                    <TextInput style={styles.input} value={firstName} onChangeText={setFirstName} />
                                </View>
                            </View>

                            <Text style={styles.label}>{t('phone_number')}</Text>
                            <TextInput style={styles.input} value={phoneNumber} onChangeText={setPhoneNumber} keyboardType="phone-pad" />

                            <Text style={styles.label}>{t('email')}</Text>
                            <TextInput style={styles.input} value={email} onChangeText={setEmail} keyboardType="email-address" />

                            <View style={styles.row}>
                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>{t('discount')}</Text>
                                    <View style={styles.percentageInputContainer}>
                                        <TextInput
                                            style={[styles.input, styles.percentageInput]}
                                            value={discountPercentage}
                                            onChangeText={(text) => {
                                                const numericValue = text.replace(/[^0-9]/g, ''); // Allow only numbers
                                                if (numericValue === '' || (parseInt(numericValue, 10) <= 100)) {
                                                    setDiscountPercentage(numericValue);
                                                }
                                            }}
                                            keyboardType="numeric"
                                        />
                                        <Text style={styles.percentageSymbol}>%</Text>
                                    </View>
                                </View>

                                <View style={styles.halfInputContainer}>
                                    <Text style={styles.label}>{t('credit')}</Text>
                                    <TextInput
                                        style={styles.input}
                                        value={creditPercentage}
                                        onChangeText={(text) => {
                                            const numericValue = text.replace(/[^0-9]/g, ''); // Allow only numbers
                                            setCreditPercentage(numericValue);
                                        }}
                                        keyboardType="numeric"
                                    />
                                </View>
                            </View>

                            <View style={styles.checkboxContainer}>
                                <Checkbox
                                    status={serviceFee ? 'checked' : 'unchecked'}
                                    onPress={() => setServiceFee(!serviceFee)}
                                />
                                <Text style={styles.checkboxLabel}>{t('service_fee')}</Text>
                            </View>

                            <View style={styles.buttonContainer}>
                                <TouchableOpacity style={[styles.button, styles.cancelButton]} onPress={() => setAddVipModalVisible(false)}>
                                    <Text style={styles.buttonText}>{t('cancel')}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, styles.saveButton]} onPress={handleSave}>
                                    <Text style={styles.buttonText}>{t('save')}</Text>
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>

            <TouchableOpacity activeOpacity={0.6} onPress={() => navigation.navigate('NotificationsScreen')}>
                <IonIcons name="notifications" size={30} style={styles.icon} />
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
    },
    icon: {
        paddingHorizontal: 10,
        color: '#6e6e6e',
    },
    searchContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#bebebe',
        borderWidth: 1,
        borderRadius: 20,
        paddingHorizontal: 15,
        marginHorizontal: 15,
    },
    searchIcon: {
        marginRight: 10,
        color: '#aaa',
    },
    searchInput: {
        flex: 1,
        height: 40,
    },
    modalOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    vipMenuContent: {
        position: 'absolute',
        width: 300,
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 8,
        maxHeight: "75svh",
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
        elevation: 5,
    },
    vipList: {
        maxHeight: 320,
    },
    filterInput: {
        padding: 8,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 2,
    },
    vipItem: {
        paddingVertical: 4,
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 10,
        marginVertical: 1,
    },
    selectedVipItem: {
        backgroundColor: '#F1B44C',
    },
    vipIcon: {
        marginRight: 12,
        color: '#F76C6C',
    },
    vipInfo: {
        flex: 1,
    },
    vipName: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#333',
    },
    vipBalance: {
        fontSize: 14,
        color: '#555',
    },
    vipDiscount: {
        fontSize: 14,
        color: '#28a745',
        fontWeight: 'bold',
    },
    percentageInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
    },
    percentageInput: {
        flex: 1,
    },
    percentageSymbol: {
        position: 'absolute',
        right: 8,
        fontSize: 16,
        top: 8,
        fontWeight: 'bold',
        color: '#656565',
    },
    button: {
        flex: 1,
        padding: 18,
        alignItems: 'center',
        borderRadius: 5,
    },
    cancelButton: {
        backgroundColor: 'grey',
        marginRight: 10,
    },
    saveButton: {
        backgroundColor: '#F1B34B',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
    modalOption: {
        paddingHorizontal: 15,
        paddingVertical: 12,
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: '#ccc',
    },
    addVipModalContent: {
        width: Dimensions.get('window').width > 600 ? '37%' : '90%',
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
        elevation: 5,
    },
    container: {
        padding: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 3,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    halfInputContainer: {
        flex: 1,
        marginRight: 10,
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    checkboxLabel: {
        marginLeft: 8,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default CustomHeader;