import React, {createContext, useState} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Alert, Platform, ToastAndroid} from 'react-native';
import {getAccessToken} from './storage';
import Observables from "@happy-gastro/global-observables";
import Toast from "react-native-toast-message";

export const OrdersContext = createContext();

const OrdersProvider = ({children}) => {
    const [orders, setOrders] = useState([]);
    const [isConnected, setIsConnected] = useState(false); // Tracks network connectivity
    const [client, setClient] = useState(null); // MQTT or WebSocket client instance
    const [syncInterval, setSyncInterval] = useState(null); // Interval for offline sync
    const API_URL = 'https://api.happygastro.hu/api';

    // Fetch orders from the API
    const fetchOrdersFromAPI = async () => {
        try {
            const token = await getAccessToken(); // Retrieve the token from AsyncStorage


            const restaurantData = await AsyncStorage.getItem("restaurant");
            if(!token || !restaurantData) return;
            const URL_ENDING = '/restaurant/' + JSON.parse(restaurantData)._id + '/order?all=true&lowKey=false&beta=false&mobile=false&newType=false';

            const response = await fetch(API_URL + URL_ENDING, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setOrders(data);
            AsyncStorage.setItem('orders', JSON.stringify(data)); // Cache orders offline

            // Check for online orders to notify user
            const onlineOrderName = (orderFrom) => {
                switch (orderFrom) {
                    case 'netpincer': return "Foodora";
                    case 'foodora': return "Foodora";
                    case 'foodpanda': return "Foodora";
                    case 'wolt': return "Wolt";
                    case 'webshop': return "Webshop";
                    default: return "Online";
                }
            }
            const pendingOnlineOrders = data.filter(order => (order.status === "pending" && (order.orderType === "online" || order.orderType === "takeAway"))) || [];
            if(pendingOnlineOrders.length > 0){
                if(Platform.OS === "android") {
                    const PushNotification = require("react-native-push-notification");
                    PushNotification.localNotification({
                        channelId: "pending-orders", // Ensure this matches your channel ID
                        title: pendingOnlineOrders.length + " függőben lévő rendelés", // Notification title
                        message: "Rendelés várakozik! Kattints ide, és fogadd el gyorsan, hogy az ügyfél elégedett legyen.", // Notification message
                    });
                }
                pendingOnlineOrders.forEach((order, index) => {
                    setTimeout(() => {
                        Toast.show({
                            id: order._id,
                            type: 'pendingOrderToast',
                            position: 'bottom',
                            bottomOffset: 100,
                            autoHide: false,
                            props: { ...order, message: "Függőben lévő "+onlineOrderName(order.orderFrom)+" rendelés: #"+order.orderCode }
                        });
                    }, (index * 2000))
                })
            }
        } catch (error) {
            if(Platform.OS === "android") alert(error.message);
            if(Platform.OS === "web") alert(error.message);
            console.error("Failed to fetch orders from API:", error);
            //loadOrdersFromStorage(); // Load from storage if the API fails
        }
    };

    const storeOrder = async (orderData) => {
        const token = await getAccessToken()

        //setOrders([...orders, orderData]);
        //AsyncStorage.setItem('orders', JSON.stringify([...orders, orderData]));

        return fetch(API_URL + '/order/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
            body: JSON.stringify(orderData),
        })
            .then(response => response.json())
            .then(data => {
                setOrders([...orders, data]);
                //AsyncStorage.setItem('orders', JSON.stringify([...orders, data]));
                return data;
            })
            .catch(error => {
                console.error("Failed to store order:", error);
                //storeOrderOffline(orderData); // Store offline if API fails
                return false
            });
    }

    // Load orders from AsyncStorage (offline cache)
    const loadOrdersFromStorage = async () => {
        try {
            const storedOrders = await AsyncStorage.getItem('orders');
            if (storedOrders) setOrders(JSON.parse(storedOrders));
        } catch (error) {
            console.error("Failed to load orders from storage:", error);
        }
    };

    // Store new orders locally for later sync
    const storeOrderOffline = async (order) => {
        try {
            const storedOrders = await AsyncStorage.getItem('offlineOrders');
            const offlineOrders = storedOrders ? JSON.parse(storedOrders) : [];
            offlineOrders.push(order);
            await AsyncStorage.setItem('offlineOrders', JSON.stringify(offlineOrders));
        } catch (error) {
            console.error("Failed to store order offline:", error);
        }
    };

    // Sync offline orders with the API
    const syncOrdersWithAPI = async () => {
        /*
        try {
            const storedOrders = await AsyncStorage.getItem('offlineOrders');
            if (!storedOrders) return;

            const offlineOrders = JSON.parse(storedOrders);
            for (const order of offlineOrders) {
                await fetch(API_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(order),
                });
            }

            await AsyncStorage.removeItem('offlineOrders'); // Clear synced orders
        } catch (error) {
            console.error("Failed to sync orders with API:", error);
        }
         */
    };

    // MQTT/WebSocket setup
    const setupRealTimeUpdates = () => {
        try {
            // Fallback to periodic API polling every 10 seconds
            const interval = setInterval(fetchOrdersFromAPI, 10 * 1000);
            setSyncInterval(interval);
        } catch (error) {
            console.error("Failed to setup real-time updates:", error);
        }
    };

    React.useEffect(() => {
        fetchOrdersFromAPI(); // Fetch orders on component mount
        setupRealTimeUpdates(); // Setup real-time updates
        Observables.register("reloadOrders", fetchOrdersFromAPI);
        return () => {
            clearInterval(syncInterval);
            setSyncInterval(null);
        } // Cleanup on unmount
    }, []);

    return (
        <OrdersContext.Provider value={{orders, storeOrderOffline, storeOrder, fetchOrdersFromAPI}}>
            {children}
        </OrdersContext.Provider>
    );
};
export default React.memo(OrdersProvider)