import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Dimensions,
    FlatList,
    Image,
    Modal,
    Platform,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    Vibration,
    View
} from 'react-native';
import { OnlineOrderContext } from '../helpers/OnlineOrderContext';
import {LinearGradient} from "expo-linear-gradient";
import CustomHeader from "../helpers/CustomHeader";
import PlaceholderImage from "../../assets/placeholder.png";
import PairingModal from '../components/PairingModal';
import {CartContext} from '../helpers/CartContext';
import {generateObjectId} from "../helpers/objectId";
import {priceFormatter} from "../helpers/numbers";
import {isColorDark} from "../helpers/colors";
import LottieView from "lottie-react-native";
import dataLoadingAnimation from "../../assets/animations/data-loading.json";
import noDataAnimation from "../../assets/animations/no-item-found.json";
import {SystemContext} from '../helpers/SystemContext';
import Observables from "@happy-gastro/global-observables";
import Icon from 'react-native-vector-icons/FontAwesome';
import {TableContext} from '../helpers/TableContext';
import {OrdersContext} from "../helpers/OrdersContext";
import {Ionicons} from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import {useRoute} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import ExtrasModal from "../components/ExtrasModal";
import {VipCardContext} from "../helpers/VipCardContext";

const { height, width } = Dimensions.get('window');


const EventBox = (props) => {
    const { ev, onClickEvent, ticketCount } = props;

    const setEvent = () => {
        Vibration.vibrate(30);
        props.onClickEvent(ev);
    };
    const isDark = isColorDark(ev.color || '#fefe');

    return (
        <TouchableOpacity
            accessibilityRole="button"
            style={{ width: '100%', backgroundColor: ev.color ? ev.color : '#fff' }}
            onPress={() => setEvent()}
        >
            {Platform.OS !== 'web' ? (
                <Image
                    height={80}
                    resizeMode={'cover'}
                    resizeMethod={'auto'}
                    style={[styles.productImage, { width: '100%', height: 80, resizeMode: 'cover' }]}
                    source={PlaceholderImage}
                />
            ) : (
                <img width={'100%'} src="https://placehold.co/100x102" style={styles.productImage} alt="product" />
            )}
            <Text style={[styles.productName, { color: isDark ? '#fff' : '#000' }]}>{ev.name}</Text>
            {ticketCount > 0 && (
                <View style={styles.badgeContainer(true)}>
                    <Text style={styles.badgeText}>{ticketCount}</Text>
                </View>
            )}
        </TouchableOpacity>
    );
};
const EventsList = (props) => {
    const { cartItems } = useContext(CartContext);
    const { eventColumnCount } = useContext(SystemContext);

    const getTicketCountForEvent = (event) => {
        return cartItems.reduce((total, item) => {
            if (event?.tickets?.some(ticket => ticket.id === item.itemId)) {
                return total + item?.quantity;
            }
            return total;
        }, 0);
    };

    const renderItem = ({ item, index }) => {
        const isLastItem = index === props.events.length - 1;
        const isOdd = props.events.length % 2 !== 0;
        const ticketCount = getTicketCountForEvent(item);
        return (
            <View style={[styles.productBox, isLastItem && isOdd ? styles.fullWidth : null]}>
                <EventBox ev={item} onClickEvent={props.onClickEvent} ticketCount={ticketCount} />
            </View>
        );
    };

    return (
        <View style={{ flex: 1 }}>
            <FlatList
                data={props.events}
                accessibilityRole={'list'}
                renderItem={renderItem}
                keyExtractor={item => item.id}
                numColumns={eventColumnCount || 2}
                key={eventColumnCount + "columnsEvent"}
                contentContainerStyle={styles.container}
            />
        </View>
    );
};

const TicketRow = (props) => {
    const { ticket, onClickEvent } = props;
    const { cartItems } = useContext(CartContext);
    const isDark = isColorDark(ticket.color || '#fefe');
    const [tempCount, setTempCount] = useState(0);
    const [ticketCount, setTicketCount] = useState(0);

    const setTicket = () => {
        Vibration.vibrate(30);
        setTempCount(tempCount + 1);
        onClickEvent(ticket);
    };

    const getTicketCount = (ticketId) => {
        const items = cartItems.filter(cartItem => cartItem.itemId === ticketId);
        return items.reduce((total, item) => total + item.quantity, 0);
    };

    useEffect(() => {
        setTicketCount(getTicketCount(ticket.id));
    }, [cartItems]);

    useEffect(() => {
        setTempCount(0);
    }, [ticketCount]);

    return (
        <TouchableOpacity
            accessibilityRole="button"
            style={[styles.ticketRow, { backgroundColor: ticket.color || '#fefe' }]}
            onPress={setTicket}
        >
            <Text style={[styles.ticketName, { color: isDark ? '#fff' : '#000' }]}>
                {ticket.name}
            </Text>
            {(ticketCount + tempCount) > 0 && (
                <View style={styles.badgeContainer(false)}>
                    <Text style={styles.badgeText}>{ticketCount + tempCount}</Text>
                </View>
            )}
            <Text style={[styles.ticketPrice, { color: isDark ? '#fff' : '#000' }]}>
                {priceFormatter(ticket.price)}
            </Text>
        </TouchableOpacity>
    );
};
const TicketList = (props) => {
    const { t } = useTranslation();
    const Breadcrumb = () => (
        <TouchableOpacity accessibilityRole="link" onPress={props.goBack} style={styles.breadcrumbContainer}>
            <Text style={styles.breadcrumbText}>{'<'} {props?.currentEvent?.name || t('tickets')}</Text>
        </TouchableOpacity>
    );

    return (
        <View style={{ flex: 1 }}>
            <FlatList
                accessibilityRole="list"
                data={props.events.find(e => e.id === props.currentEvent.id).tickets}
                renderItem={({ item }) => <TicketRow ticket={item} onClickEvent={() => props.onClickTicket(item)} />}
                keyExtractor={item => item.id}
                numColumns={1}
                contentContainerStyle={[styles.container, { marginTop: 12 }]}
            />
        </View>
    );
};

const CategoryBox = (props) => {
    const { t } = useTranslation();
    const { category, onClickCategory, itemCount } = props;
    const setCategory = () => {
        Vibration.vibrate(30);
        props.onClickCategory(category);
    };
    const isDark = isColorDark(category?.color || '#fefe');

    return (
        <TouchableOpacity
            accessibilityRole="button"
            style={{ width: '100%', backgroundColor: category?.color ? category?.color : '#fff' }}
            onPress={() => setCategory()}
        >
            {Platform.OS !== 'web' ? (
                <Image
                    height={80}
                    resizeMode={'cover'}
                    resizeMethod={'auto'}
                    style={[styles.productImage, { width: '100%', height: 60, resizeMode: 'cover' }]}
                    source={{ uri: 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/food-no-img.jpg' }}
                />
            ) : (
                <img width={'100%'} src={"https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/food-no-img.jpg"} style={styles.productImage} alt="category" />
            )}
            <Text style={[styles.productName, { color: isDark ? '#fff' : '#000', height: 40, alignItems: 'center', alignSelf: 'center', display: 'flex' }]}>
                {category?.name?.hu || t('category_name')}
            </Text>
            {itemCount > 0 && (
                <View style={styles.badgeContainer(true)}>
                    <Text style={styles.badgeText}>{itemCount}</Text>
                </View>
            )}
        </TouchableOpacity>
    );
};

const CategoryList = (props) => {
    const { cartItems } = useContext(CartContext);
    const { categoryColumnCount } = useContext(SystemContext);

    const getItemCountForCategory = (categoryId) => {
        return cartItems.reduce((total, item) => {
            if (item?.category?._id === categoryId || item?.category?.parent === categoryId) {
                return total + item.quantity;
            }
            return total;
        }, 0);
    };

    const renderItem = ({ item, index }) => {
        const isLastItem = index === props.categories.length - 1;
        const isOdd = props.categories.length % 3 !== 0;
        const itemCount = getItemCountForCategory(item._id);
        return (
            <View style={[styles.productBox, isLastItem && isOdd ? styles.fullWidth : null]}>
                <CategoryBox category={item} onClickCategory={props.onClickCategory} itemCount={itemCount} />
            </View>
        );
    };

    return (
        <View style={{ flex: 1 }}>
            <FlatList
                data={props.categories}
                accessibilityRole={'list'}
                renderItem={renderItem}
                keyExtractor={item => item._id}
                numColumns={categoryColumnCount || 3}
                key={categoryColumnCount + "columnsCat"}
                contentContainerStyle={styles.container}
            />
        </View>
    );
};

const FoodRow = (props) => {
    const { t } = useTranslation();
    const { food, onClickFood } = props;
    const { cartItems } = useContext(CartContext);
    const isDark = isColorDark(food?.color || '#fefe');
    const [tempCount, setTempCount] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);

    const setTicket = () => {
        Vibration.vibrate(30);
        if (food.sizes.length > 1) {
            setSelectedFood(food);
            setModalVisible(true);
        } else {
            setTempCount(tempCount + 1);
            onClickFood({...food, price: food.sizes[0].price, unit: food.sizes[0].unit});
        }
    };

    const getItemCount = (itemId) => {
        const items = cartItems.filter(cartItem => cartItem.itemId === itemId);
        return items.reduce((total, item) => total + item.quantity, 0);
    };

    useEffect(() => {
        setItemCount(getItemCount(food._id));
    }, [cartItems]);

    useEffect(() => {
        setTempCount(0);
    }, [itemCount]);

    const handleSizeSelect = (size, index) => {
        onClickFood({...selectedFood, price: size.price, unit: size.unit, sizeIndex: index});
        setModalVisible(false);
        setSelectedFood(null);
    };

    return (
        <TouchableOpacity
            accessibilityRole="button"
            style={[styles.ticketRow, { backgroundColor: food.color || '#fefe' }]}
            onPress={setTicket}
        >
            {food?.description?.hu || food?.description?.en ?
                <View>
                    <Text style={[styles.ticketName, { color: isDark ? '#fff' : '#000'}]}>
                        {food?.name?.hu || ""}
                    </Text>
                    <Text style={styles.descriptionText}>
                        {food?.description?.hu || food?.description?.en}
                    </Text>
                </View>
                :
                <Text style={[styles.ticketName, { color: isDark ? '#fff' : '#000', marginVertical: 8 }]}>
                    {food?.name?.hu || ""}
                </Text>
            }
            {(itemCount + tempCount) > 0 && (
                <View style={styles.badgeContainer(false)}>
                    <Text style={styles.badgeText}>{itemCount + tempCount}</Text>
                </View>
            )}
            <Text style={[styles.ticketPrice, { color: isDark ? '#fff' : '#000' }]}>
                {priceFormatter(food?.sizes[0]?.price || 0)}
            </Text>
            {modalVisible && (
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible);
                    }}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold'}}>{selectedFood?.name?.hu || selectedFood?.name}</Text>
                            <Text style={{  marginBottom: 10 }}>{t('multiple_sizes_available')}</Text>
                            {selectedFood.sizes.map((size, index) => (
                                <TouchableOpacity
                                    key={index}
                                    onPress={() => handleSizeSelect(size, index)}
                                    style={styles.button}
                                >
                                    <Text style={styles.buttonText}>{`${size.amount} ${size.unit} - ${priceFormatter(size.price)}`}</Text>
                                </TouchableOpacity>
                            ))}
                            <TouchableOpacity
                                onPress={() => setModalVisible(!modalVisible)}
                                style={styles.cancelButton}
                            >
                                <Text>{t('cancel')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
            )}
        </TouchableOpacity>
    );
};

const FoodList = (props) => {
    const { t } = useTranslation();
    const foods = props.isSearched ? props.foods : props.foods.filter(f => (f.category === props.currentCategory?._id || f.category === props.currentCategory?._id));
    const hasFoods = foods.length > 0;
    return (
        <View style={{ flex: 1 }}>
            {!hasFoods && <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginBottom: 60 }}>
                <LottieView
                    source={noDataAnimation}
                    autoPlay
                    loop={true}
                    style={styles.lottie}
                    renderMode={'AUTOMATIC'}
                    resizeMode={'center'}
                    enableSafeModeAndroid={true}
                />
                <Text style={{ fontSize: 22, color: 'black', marginTop: 10 }}>{t('this_group_is_empty')}</Text>
            </View>}
            {hasFoods && <FlatList
                accessibilityRole="list"
                data={foods}
                renderItem={({ item }) => <FoodRow food={item} onClickFood={props.onClickFood} />}
                keyExtractor={item => item._id}
                numColumns={1}
                contentContainerStyle={[styles.container, { marginTop: 12 }]}
            />}
        </View>
    );
};

const SaleScreen = ({ navigation, numColumns }) => {
    const { t } = useTranslation();
    const {
        defaultView, fetchSystemData, loaded, events = [], categories = [], foods = [], eventColumnCount, categoryColumnCount,
        selectedEvent, setSelectedEvent, selectedCategory, setSelectedCategory
    } = useContext(SystemContext);
    const { orderData, orderType } = useContext(OnlineOrderContext);
    const { orders } = useContext(OrdersContext);
    const { addToCart, removeFromCart, cartItems, alreadySentCartItems, setAlreadySentCartItems, setDiscountsForWholeCart } = useContext(CartContext);
    const { currentTable, removeTable } = useContext(TableContext);
    const { selectedVipCard, removeVipCard } = useContext(VipCardContext);
    const [showGrid, setShowGrid] = useState(defaultView === "categories" && events.length ? 'events' : 'categories');
    const [currentlyClickedItems, setCurrentlyClickedItems] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(!loaded);
    const [refreshing, setRefreshing] = useState(false);
    const clickCountRef = React.useRef({});
    const [searchResultFoods, setSearchResultFoods] = useState([]);
    const [searchResultEvents, setSearchResultEvents] = useState([]);

    const route = useRoute();
    const userInfo = route.params?.userInfo;
    //const orderType = route.params?.orderType;

    const [extrasModalVisible, setExtrasModalVisible] = useState(false);
    const [extrasModalItem, setExtrasModalItem] = useState(null);
    const [extrasModalCallback, setExtrasModalCallback] = useState(null);


    const onRefresh = useCallback(() => {
        fetchSystemData();
    }, []);

    useEffect(() => {
        const isSearched = searchString?.length > 1;
        const searchValues = isSearched ? searchString?.toLowerCase().split(' ') : [];
        if (searchValues.length > 0 && foods) {
            setSearchResultFoods(foods.filter(f => searchValues.some(val => f.name?.hu.toLowerCase().includes(val))));
            //setSearchResultEvents(events.filter(e => searchValues.some(val => e.name.toLowerCase().includes(val))));
        }
    }, [searchString]);

    useEffect(() => {
        Observables.register('systemDataLoaded', () => {
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        if (currentTable) {
            const tableOrder = orders?.find(
                (order) =>
                    !order.storno &&
                    order.status !== "cancelled" &&
                    order.status !== "closed" &&
                    order.tableId === currentTable._id
            );
            if (tableOrder) {
                setAlreadySentCartItems(tableOrder?.items);
                if (tableOrder?.discounts?.percentage > 0 || tableOrder?.discounts?.amount > 0) {
                    setDiscountsForWholeCart(tableOrder?.discounts);
                }
            }
        }
    }, [currentTable]);

    useEffect(() => {
        if (loaded) {
            if (!events?.length > 0) {
                setShowGrid('categories');
            }
        }
    }, [loaded]);
    useEffect(() => {
        if (loaded) {
            if (!events?.length > 0) {
                setShowGrid('categories');
            }
        }
    }, [events]);

    useEffect(() => {
    },[loading, loaded])
    useEffect(() => {
        if (currentlyClickedItems.length > 0) {
            addAllToCart();
        }
    }, [currentlyClickedItems]);

    const incrementItemCount =  (item) => {
        const itemId = item._id || item.id;

        if (!clickCountRef.current[itemId]) {
            clickCountRef.current[itemId] = { count: 0, timeout: null };
        }

        const itemRef = clickCountRef.current[itemId];
        itemRef.count += 1;

        // Clear the previous timeout for this item
        if (itemRef.timeout) {
            clearTimeout(itemRef.timeout);
        }

        // Set a new timeout specifically for this item
        itemRef.timeout = setTimeout(() => {
            setCurrentlyClickedItems(prevItems => [
                ...prevItems,
                { ...item, quantity: Math.max(itemRef.count, 1), itemId: itemId }
            ]);

            itemRef.count = 0;
            itemRef.timeout = null;
        }, 350);
        setExtrasModalVisible(false)
    }

    const handleItemClick = async (item) => {
        if (item.extras && item.extras.length > 0) {
            const result = await new Promise((resolve) => {
                setExtrasModalVisible(true);
                setExtrasModalItem(item);
                setExtrasModalCallback(resolve);
            });

            if (result) {
                // If the modal was saved, add the item to the cart
                incrementItemCount(item);
            }
        } else {
            incrementItemCount(item);
        }
    };

    const addAllToCart = () => {
        currentlyClickedItems.forEach(item => {
            addToCart({ ...item, _id: generateObjectId() });
        });
        setCurrentlyClickedItems([]);
    };

    // if (loading) {
    //     return (
    //         <LinearGradient
    //             colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
    //             style={styles.background}
    //             start={{ x: 0.3, y: 0 }}
    //             end={{ x: 0.7, y: 1 }}
    //         >
    //
    //
    //         </LinearGradient>
    //     );
    // }

    const rootCategories = categories.filter(category => !category.parent);
    const childCategories = categories.filter(category => category.parent === selectedCategory?._id);
    const childFoods = foods.filter(food => food.category?.parent === selectedCategory?._id);

    const getParentCategory = () => {
        if (selectedCategory?.parent) {
            return categories.find(
                (c) => c._id === selectedCategory.parent
            );
        }
        return "";
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        >
            {!loaded ? <View style={[styles.container, { flex: 1, alignContent: 'center', alignItems: 'center' }]}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 60 }}>
                    <LottieView
                        source={dataLoadingAnimation}
                        autoPlay
                        loop={true}
                        style={styles.lottie}
                        renderMode={'AUTOMATIC'}
                        resizeMode={'center'}
                        enableSafeModeAndroid={true}
                    />
                    <Text style={{ fontSize: 22, color: 'black', marginTop: 10 }}>{t('data_loading')} {loading},{loaded}</Text>
                </View>
            </View> : <ScrollView
                style={{ width: '100%', flex: 1, padding: 0, margin: 0, height: '100%', paddingBottom: 30 }}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            >
                <View>
                    <CustomHeader title={t('sales')} searchString={searchString} setSearchString={setSearchString} />
                </View>
                <View style={[styles.breadcrumbContainer, { backgroundColor: 'transparent' }]}>
                    <TouchableOpacity style={styles.breadcrumbButton} activeOpacity={1}>
                        {((showGrid !== "events" && selectedCategory) || (showGrid === "events" && selectedEvent)) && <Icon name={'chevron-left'} size={30} color="#000" style={styles.leftIcon} onPress={() => {
                            if (showGrid === "events") {
                                setSelectedEvent(null);
                            } else {
                                setSelectedCategory(null);
                            }
                        }} />}
                        <View style={styles.centerContent}>
                            {!selectedCategory && (<Text
                                style={{
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: 22,
                                    fontWeight: "900",
                                    animation: "fadeIn",
                                    margin: 0,
                                }}
                                onPress={() => {
                                    setSelectedCategory(null);
                                }}
                            >
                                {t('all_groups')} ({categories.length})
                            </Text>)}

                            {selectedCategory && (
                                <>
                                    <Text
                                        style={{
                                            fontFamily: "'Poppins', sans-serif",
                                            fontSize: 22,
                                            fontWeight: "900",
                                            animation: "fadeIn",
                                            margin: 0,
                                        }}
                                        onPress={() => {
                                            setSelectedCategory(null);
                                        }}
                                    >
                                        {t('all')}
                                    </Text>
                                    <Ionicons name="chevron-forward" size={24} />
                                    {getParentCategory() && (
                                        <Text
                                            style={{
                                                fontFamily: "'Poppins', sans-serif",
                                                fontSize: 22,
                                                fontWeight: "900",
                                                animation: "fadeIn",
                                                margin: 0,
                                            }}
                                            onPress={() => {
                                                setSelectedCategory(getParentCategory() || null);
                                            }}
                                        >
                                            {getParentCategory().name.hu}
                                            <Ionicons name="chevron-forward" size={24} />
                                        </Text>
                                    )}
                                    <Text
                                        style={{
                                            fontFamily: "'Poppins', sans-serif",
                                            fontSize: 22,
                                            fontWeight: "900",
                                            animation: "fadeIn",
                                            margin: 0,
                                        }}
                                    >
                                        {selectedCategory.name.hu}
                                    </Text>
                                </>
                            )}
                        </View>
                        {(showGrid === "categories" && events.length > 0 || showGrid === "events" && categories.length > 0) && <Icon name={'exchange'} size={30} color="#000" style={styles.leftIcon}
                                                                                                                                     onPress={() => setShowGrid(showGrid === 'events' ? 'categories' : 'events')} />}
                    </TouchableOpacity>
                </View>
                {orderData?.firstName && orderData?.lastName && orderType && (
                    <View style={styles.orangeLine}>
                        <View style={{flexDirection: "row"}}><Ionicons
                            name={orderType === 'online' ? 'bicycle-outline' : 'bag-handle-outline'}
                            size={20}
                            color="white"
                            style={{ marginRight: "5px" }}
                        />
                            <Text style={styles.orangeLineText}>{orderData?.firstName} {orderData?.lastName}</Text></View>
                        {orderData?.street && orderData?.city &&
                            <View style={{flexDirection: "row"}}>
                            <Text style={styles.smallAddress}>{orderData?.city}, {orderData?.street}
                            </Text>
                        </View>}




                    </View>
                )}

                {(width < 768 && currentTable) && (
                    <TouchableOpacity
                        style={styles.tableNameContainer}
                        onPress={() => {
                            removeTable();
                            navigation.navigate("TablesScreen");
                        }}
                    >
                        <Text style={styles.tableNameText}>{currentTable.name}</Text>
                        <Icon name="times" size={20} color="#fff" style={styles.rightIcon} onPress={() => {
                            removeTable();
                            setAlreadySentCartItems([]);
                            navigation.navigate("TablesScreen");
                        }} />
                    </TouchableOpacity>
                )}
                {(width >= 768 && selectedVipCard) && (
                    <View style={styles.vipCardContainer}>
                        {(selectedVipCard?.user?.name?.first.length < 1 && selectedVipCard?.user?.name?.last.length < 1)
                        || (!selectedVipCard?.user?.name && !selectedVipCard?.user?.name)
                            ?
                            <Text style={styles.vipCardText}>
                              {selectedVipCard.cardId}
                            </Text>: <Text style={styles.vipCardText}>
                            {selectedVipCard?.user?.name?.first} {selectedVipCard?.user?.name?.last}
                        </Text>}
                        <TouchableOpacity style={{backgroundColor: "#eea63b", paddingHorizontal: 20, paddingVertical: 4}} onPress={removeVipCard}>
                            <Icon name="times" size={20} color="#fff" />
                        </TouchableOpacity>
                    </View>
                )}

                {showGrid === "events" && <View style={styles.container}>
                    {!selectedEvent && searchString?.length < 2 && <EventsList events={events} numColumns={eventColumnCount || 2} onClickEvent={setSelectedEvent} />}
                    {selectedEvent && searchString?.length < 2 &&
                        <TicketList events={events} goBack={() => setSelectedEvent(null)} currentEvent={selectedEvent}
                                    onClickTicket={(ticket) => handleItemClick(ticket)} />}
                </View>}

                {showGrid === "categories" && <View style={styles.container}>
                    {!selectedCategory && searchString?.length < 2 && <CategoryList categories={rootCategories} onClickCategory={setSelectedCategory} />}
                    {selectedCategory && searchString?.length < 2 && <View style={styles.container}>
                        {childCategories.length > 0 ? (
                            <CategoryList categories={childCategories} onClickCategory={setSelectedCategory} />
                        ) : (
                            <FoodList foods={foods} currentCategory={selectedCategory} isSearched={false} goBack={() => setSelectedCategory(null)} onClickFood={(food) => handleItemClick(food)} />
                        )}
                    </View>}
                    {searchString?.length > 1 &&
                        <View style={styles.container}>
                            {searchResultFoods &&
                            <FoodList foods={[...searchResultFoods]} isSearched={true} currentCategory={selectedCategory} goBack={() => setSearchString("")} onClickFood={(food) => handleItemClick(food)} />
                            }

                        </View>
                    }
                </View>}
                <ExtrasModal
                    visible={extrasModalVisible}
                    item={extrasModalItem}
                    onSave={incrementItemCount}
                    onClose={setExtrasModalVisible}
                />
            </ScrollView>}


        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    breadcrumbContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 6,
        backgroundColor: '#f9f9f9',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        zIndex: 6,
    },
    tableNameContainer: {
        padding: 6,
        backgroundColor: '#16a6ff',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 30, paddingRight: 30,
        justifyContent: 'space-between'
    },
    tableNameText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#fff3e9',
    },
    breadcrumbButton: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    leftIcon: {
        marginRight: 10,
    },
    rightIcon: {
        marginLeft: 10,
    },
    centerContent: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    breadcrumbText: {
        fontSize: 20,
        color: '#333',
        fontWeight: 'bold',
        marginLeft: 10,
        textAlign: 'center'
    },
    container: {
        zIndex: 10,
        flex: 1,
        padding: 6,
        width: '100%'
    },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    ticketRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        marginHorizontal: 10,
        marginBottom: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 3,
    },
    ticketName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    descriptionText: {
        fontSize: 12,
        color: 'grey',
        marginTop: 4,
    },
    ticketPrice: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    balanceBox: {
        padding: 20,
        backgroundColor: '#f1b34b',
        borderRadius: 10,
        marginBottom: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 2.84,
        elevation: 20,
    },
    balanceText: {
        fontSize: 24,
        color: '#fff',
        fontWeight: 'bold',
    },
    transactionList: {
        paddingBottom: 20,
    },
    transactionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 10,
        marginBottom: 10,
    },
    orangeLine: {
        backgroundColor: '#50A5F1',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    orangeLineText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 22,
    },
    smallAddress: {
        color: 'white',
        fontSize: 16,
    },
    transactionDescription: {
        fontSize: 16,
    },
    transactionAmount: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    button: {
        marginVertical: 5,
        padding: 12,
        backgroundColor: '#F76E24',
        borderRadius: 10,
    },
    cancelButton: {
        marginTop: 10,
        padding: 12,
        backgroundColor: '#b1b1b1',
        borderRadius: 5,
    },
    buttonText: {
        fontSize: 20,
        color: 'white',
    },
    modalView: {
        margin: 15,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 20,
        paddingHorizontal: 30,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
    productBox: {
        flex: 1,
        margin: 5,
        maxWidth: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: 4,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 5,
    },
    fullWidth: {
        flex: 1,
        width: '100%',
    },
    productImage: {
        width: "100%",
        height: 80,
        marginBottom: 0,
        cover: 'cover',
        objectFit: 'cover',
    },
    productName: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 5
    },
    vipCardContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f1b34b',
        padding: 10,
        borderRadius: 5,
    },
    vipCardText: {
        flex: 1,
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    lottie: {
        width: 300,
        height: 300
    },
    clickCountCircle: {
        position: 'absolute',
        top: -10,
        right: -10,
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: 'red',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clickCountText: {
        color: 'white',
        fontWeight: 'bold',
    },
    badgeContainer: (isBox) => ({
        position: 'absolute',
        top: -8, // Adjust for more precise positioning
        left: isBox ? -4 : -17, // Conditional left position
        backgroundColor: '#F76E24', // Matches the red badge color
        borderRadius: 14.5, // Smaller size for compact look
        width: 29, // Reduced width
        height: 29, // Reduced height
        alignItems: 'center',
        justifyContent: 'center',
    }),
    badgeText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18, // Compact font size for the number
        textAlign: 'center',
    },
});

export default React.memo(SaleScreen);