import React, { useEffect, useState, useRef, useContext } from 'react';
import { View, StyleSheet, Dimensions, Animated, Text, TouchableOpacity } from 'react-native';
import LottieView from 'lottie-react-native';
import { BluetoothContext } from "../helpers/BluetoothContext";
import { SystemContext } from '../helpers/SystemContext';
import {TableContext} from "../helpers/TableContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {CartContext} from "../helpers/CartContext";
import {VipCardContext} from "../helpers/VipCardContext";
import {OnlineOrderContext} from "../helpers/OnlineOrderContext";

const SuccessPaymentScreen = ({ navigation, route }) => {
    const [animationFinished, setAnimationFinished] = useState(false);
    const { sendData } = useContext(BluetoothContext);
    const { setSelectedCategory, setSelectedEvent, fetchSystemData } = useContext(SystemContext);
    const { removeTable } = useContext(TableContext);
    const {setDiscountsForWholeCart, setAlreadySentCartItems} = useContext(CartContext);
    const { removeVipCard } = useContext(VipCardContext);
    const { resetOnlineOrder } = useContext(OnlineOrderContext);

    const [backgroundColor, setBackgroundColor] = useState('#81F174');
    const logoOpacity = useRef(new Animated.Value(0)).current;
    const animation = useRef(null);
    const { orderResponse } = route.params; // Access orderResponse from route.params
    const handleAnimationFinish = async () => {
        setBackgroundColor('#81F174');
        setAnimationFinished(true);
    };

    const handleHomeNavigation = async () => {
        const runNavigation = async () => {
            setSelectedCategory(null);
            setSelectedEvent(null);
            removeTable();
            await AsyncStorage.removeItem('currentTable');
            setDiscountsForWholeCart({amount: 0, percentage: 0});
            setAlreadySentCartItems([]);
            removeVipCard();
            fetchSystemData();
            resetOnlineOrder();
            navigation.navigate("HomeScreen", {screen: "Sale"});
        }
        new Promise(() =>runNavigation())
    };

    return (
        <View style={[styles.container, { backgroundColor: '#81F174' }]}>
            {animationFinished && (
                <Animated.View style={[styles.logoContainer, { opacity: 1 }]}>
                    <LottieView
                        source={require('../../assets/animations/success_center.json')} // Path to your Lottie animation file
                        autoPlay
                        loop={true}
                        style={styles.lottieCenter}
                    />
                    <Text style={styles.logo}>Sikeres rendelés!</Text>
                    <Text style={[styles.logo, { fontSize: 20, textAlign: 'center' }]}>A rendelés azonosítója: #{orderResponse.orderCode}</Text>

                    <TouchableOpacity style={styles.button} onPress={handleHomeNavigation}>
                        <Text style={styles.buttonText}>Tovább a tételekhez</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={[styles.button, {marginTop: 10, backgroundColor: "black"}]}
                                      onPress={() => {
                                          new Promise(() => sendData('fiscat/AEE|PEJ|4'));
                                      }}>
                        <Text style={[styles.buttonText, {color: 'white'}]}>Nyugta újranyomtatása</Text>
                    </TouchableOpacity>
                </Animated.View>
            )}
            {!animationFinished && (
                <LottieView
                    ref={animation}
                    source={require('../../assets/animations/success_animation.json')} // Path to your Lottie animation file
                    autoPlay
                    loop={false}
                    speed={2}
                    onAnimationFinish={handleAnimationFinish}
                    resizeMode={'contain'}
                    renderMode={'HARDWARE'}
                    style={[styles.lottie, { width: Dimensions.get('screen').width, height: Dimensions.get('screen').height }]}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#81F174',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').height,
    },
    lottie: {
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').height,
    },
    lottieCenter: {
        width: Dimensions.get('screen').width - 100,
        height: Dimensions.get('screen').width - 100,
    },
    logoContainer: {
        position: 'absolute',
        alignItems: 'center',
        zIndex: 99999999
    },
    logo: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#FFF',
    },
    text: {
        fontSize: 40,
        fontWeight: 'bold',
        color: 'black',
        marginTop: 10,
    },
    button: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
        marginTop: 40
    },
    buttonText: {
        fontSize: 24,
        color: "white",
        paddingHorizontal: 20,
        textAlign: 'center',
    },
});

export default SuccessPaymentScreen;