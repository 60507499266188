import React, {useContext} from 'react';
import {
    Dimensions,
    FlatList,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {PieChart} from "react-native-chart-kit";
import TimeAgo from "react-native-timeago";
import {priceFormatter} from '../helpers/numbers';
import {OrdersContext} from "../helpers/OrdersContext";
import StatisticsHelper from "../helpers/StatisticsHelper";
import Observables from "@happy-gastro/global-observables";
import LottieView from "lottie-react-native";
import {getOrderPlatform, getOrderType} from "../helpers/orderDetailsHelper";

import { useTranslation } from 'react-i18next';

const { width, height } = Dimensions.get("window");

const isTablet = width >= 768 && width < 1024;
const isDesktop = width >= 1024;

export default function StatisticsScreen({ navigation }) {
    const { t } = useTranslation();
    const { orders } = useContext(OrdersContext);
    const { productsSum, statistics } = StatisticsHelper(orders);

    const [refreshing, setRefreshing] = React.useState(false);

    const onRefresh = async () => {
        setRefreshing(true);
        Observables.call("refreshOrders");
        setRefreshing(false);
    };

    if (!statistics.finalSum || statistics.finalSum === 0) {
        return (
            <LinearGradient
                colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
                style={styles.background}
            >
                <View style={[styles.containerAnimation, { justifyContent: 'center' }]}>
                    <LottieView source={require('../../assets/animations/no-data-here.json')} autoPlay loop
                                style={styles.animation} />
                    <Text style={styles.warningText}>{t("no_data_today")}</Text>
                </View>
            </LinearGradient>
        )
    }

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ width: '100%', height: '100vh' }}
        >
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
            <SafeAreaView>
                <View style={{ height: '90vh', flexGrow: 1, display: 'flex' }}>
                    <ScrollView styles={{ height: "90vh" }} contentContainerStyle={{ height: '90vh' }}>
                        <View style={styles.container}>
                            {/*Traffic Data */}
                            <View style={[styles.section, { marginTop: 15 }]}>
                                <Text style={styles.title}>{t("traffic_data")}</Text>
                                <View style={styles.row}>
                                    <Text>{t("product_traffic")}:</Text>
                                    <Text style={{ fontWeight: 'bold' }}>{priceFormatter(statistics.productsSum || 0)}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text>{t("service_fees")}:</Text>
                                    <Text style={{ fontWeight: 'bold' }}>{priceFormatter(statistics.serviceFeePrice || 0)}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={{ fontWeight: "700", fontSize: 17 }}>{t("total_traffic")}:</Text>
                                    <Text style={{
                                        fontWeight: "700",
                                        fontSize: 17,
                                    }}>{priceFormatter((statistics.finalSum) || 0)}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text>{t("tip_takeaway")}:</Text>
                                    <Text style={{ fontWeight: 'bold' }}>{priceFormatter(statistics?.tipAmount?.takeAway || 0)}</Text>
                                </View>
                            </View>

                            {/* Payment Method */}
                            <View style={[styles.section, { flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }]}>
                                <View style={{ width: '50%' }}>
                                    <Text style={styles.title}>{t("payment_method")}</Text>
                                    <View style={styles.row}>
                                        <Text>{t("cash")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.cashOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("store_credit_card")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.creditCardOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("online_credit_card")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.onlineCreditCardOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("wolt_credit_card")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.woltCreditCardOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.woltCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("vip_balance")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{[].length} {t("orders")}
                                            / {priceFormatter([].reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                </View>
                                <View>
                                    <PieChart
                                        data={[
                                            {
                                                name: t('cash'),
                                                population: statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0),
                                                color: '#50b600',
                                                legendFontColor: '#7F7F7F',
                                                legendFontSize: 12
                                            },
                                            {
                                                name: t('store_credit_card'),
                                                population: statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0),
                                                color: '#4287f5',
                                                legendFontColor: '#7F7F7F',
                                                legendFontSize: 12
                                            },
                                            {
                                                name: t('online_credit_card'),
                                                population: statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0),
                                                color: '#5345c9',
                                                legendFontColor: '#7F7F7F',
                                                legendFontSize: 12
                                            },
                                            {
                                                name: t('voucher'),
                                                population: 0,
                                                color: '#e08a55',
                                                legendFontColor: '#7F7F7F',
                                                legendFontSize: 12
                                            },
                                        ]}
                                        width={250}
                                        height={180}
                                        chartConfig={{
                                            backgroundColor: '#1cc910',
                                            backgroundGradientFrom: '#eff3ff',
                                            backgroundGradientTo: '#efefef',
                                            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                                        }}
                                        accessor="population"
                                        backgroundColor="transparent"
                                        paddingLeft="15"
                                        absolute
                                    />
                                </View>
                            </View>

                            {/* by modules */}
                            <View style={[styles.section, { flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }]}>
                                <View style={{ width: '100%' }}>
                                    <Text style={styles.title}>{t("by_modules")}</Text>
                                    <View style={styles.row}>
                                        <Text>{t("takeaway_orders")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.takeAwayOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.takeAwayOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("dine_in_orders")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.dineInOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.dineInOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("home_delivery_orders")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.onlineOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.onlineOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                </View>
                            </View>

                            {/* by platforms */}
                            <View style={[styles.section, { flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }]}>
                                <View style={{ width: '100%' }}>
                                    <Text style={styles.title}>{t("by_platforms")}</Text>
                                    <View style={styles.row}>
                                        <Text>{t("wolt")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.woltOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.woltOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("foodora")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.foodoraOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.foodoraOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("webshop")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.webshopOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.webshopOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("phone_orders")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.phoneOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.phoneOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text>{t("other_orders")}:</Text>
                                        <Text style={{ fontWeight: 'bold' }}>{statistics.otherOrders.length} {t("orders")}
                                            / {priceFormatter(statistics.otherOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                                    </View>
                                </View>
                            </View>

                            {/* VAT Breakdown */}
                            <View style={styles.section}>
                                <Text style={styles.title}>{t("vat_breakdown")}</Text>
                                <View style={styles.tableContainer}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>0%</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>5%</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>18%</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>27%</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                        <Text style={styles.tableCell}>0 Ft</Text>
                                    </View>
                                </View>
                            </View>

                            {/* Invoice List */}
                            <View style={styles.section}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.title}>{t("latest_invoices")}</Text>
                                    <Text style={styles.title}>({statistics.allOrders.length} {t("pcs")})</Text>
                                </View>
                                <FlatList
                                    data={[
                                        ...statistics.allOrders.slice(0, 5).map(order => ({
                                            id: '#' + order.orderCode,
                                            amount: priceFormatter(order.orderSum),
                                            when: order.createdAt,
                                            orderData: order
                                        })),
                                    ]}
                                    renderItem={({ item }) => (
                                        <TouchableOpacity style={[styles.row, { marginVertical: 10 }]}
                                                          onPress={() => navigation.navigate('OrderDetailsScreen', { orderData: item.orderData,  orderId: item.orderData?._id })}>
                                            <Text style={{ fontWeight: "700", flex: 1 }}><TimeAgo time={new Date(item.when)}
                                                                                                  interval={10} /></Text>
                                            <Text style={{ fontWeight: "700", flex: 1, textAlign: 'center' }}>{item.id}</Text>
                                            <Text style={{ fontWeight: "700", flex: 1, textAlign: 'center' }}>{getOrderType(item.orderData.orderType)}</Text>
                                            <Text style={{ fontWeight: "700", flex: 1, textAlign: 'center' }}>{getOrderPlatform(item.orderData.orderFrom)}</Text>
                                            <Text
                                                style={{
                                                    fontWeight: "700",
                                                    flex: 1,
                                                    textAlign: 'right'
                                                }}>{item.amount}</Text>
                                        </TouchableOpacity>
                                    )}
                                    keyExtractor={(item) => item.id}
                                />
                                {statistics.allOrders.length > 5 &&
                                    <TouchableOpacity style={[styles.row, styles.ordersButton, { marginVertical: 10 }]}
                                                      onPress={() => navigation.navigate('OrderListScreen', { orders: [...statistics.orders, ...statistics.cancelledOrders, ...statistics.stornoOrders] })}>
                                        <Text style={{ flex: 1, textAlign: 'center', color: 'white', fontSize: 16 }}>{t("full_invoice_list")}</Text>
                                    </TouchableOpacity>}
                            </View>

                            {/* Other Traffic Data */}
                            <View style={[styles.section]}>
                                <Text style={styles.title}>{t("other_traffic_data")}</Text>
                                <View style={styles.row}>
                                    <Text>{t("item_storno")}:</Text>
                                    <Text>0 {t("pcs")} / 0 Ft</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text>{t("average_value")}:</Text>
                                    <Text>-- Ft</Text>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    ordersButton: {
        backgroundColor: "#F1B34B",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        width: '100%',
        color: 'white'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingHorizontal: 16,
    },
    background: {
        flex: 1,
    },
    warningText: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    animation: {
        width: 100,
        height: 100,
        alignSelf: 'center', // Center the animation horizontally
    },

    headerTitle: {
        fontSize: 19,
        fontWeight: "600",
        color: '#000',
        textAlign: 'center',
        marginTop: -7
    },
    content: {
        paddingHorizontal: 16,
        marginTop: 15,
        backgroundColor: 'transparent',
    },
    containerAnimation: {
        flex: 1,
        width: '70%',
        height: '70%',
        paddingTop: 16,
        marginBottom: 150,
        alignSelf: 'center',
        alignItems: 'center'

    },
    fixedButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 20,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fullWidthBox: {
        width: '100%',
        padding: 20,
        backgroundColor: '#FEFEFE',
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 10,
    },
    statsContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    statText: {
        fontSize: 20,
    },
    tableContainer: {
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 5,
    },
    tableCell: {
        flex: 1,
        textAlign: 'center',
        fontSize: 15,
    },
    orderItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15,
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 10,
    },
    orderId: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    orderSum: {
        fontSize: 20,
        color: '#333',
    },
    closeDayButton: {
        backgroundColor: 'orange',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 15,
        width: '100%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    closeDayButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    boxText: {
        fontSize: 23,
        fontWeight: '600',
        color: '#292929',
    },

    container: {
        flex: 1,
        //backgroundColor: '#fff',
        marginHorizontal: 16,
        marginBottom: 20,
    },
    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {width: 0, height: 2},
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});