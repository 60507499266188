import React, {useContext, useState} from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import { useTranslation } from "react-i18next";
import HappyAlert from '../helpers/crossPlatformTools/HappyAlert';
import { getAccessToken } from "../helpers/storage";
import axios from "axios";
import { ApiConfig } from "../config/ApiConfig";
import Observables from "@happy-gastro/global-observables";
import {BluetoothContext} from "../helpers/BluetoothContext";
const { width, height } = Dimensions.get('window');

const SuccessModal = ({ visible, order, onClose }) => {

    const [isAlertVisible, setIsAlertVisible] = useState(false); // State for HappyAlert visibility
    const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false); // State for success HappyAlert visibility
    const { t } = useTranslation();
    const { sendData } = useContext(BluetoothContext);

    if (!visible) return null;

    const handleCloseInvoice = () => {
        setIsAlertVisible(true);
    };

    const handleOrderClose = async () => {
        const token = await getAccessToken();
        await axios.post(ApiConfig.apiURL + `/api/order/${order._id}/update`, {
            status: "closed",
        }, {
            headers: {
                "Accept-Language": "hu_HU",
                "authorization": `Bearer ${token}`,
            },
        });
        Observables.call("reloadOrders");
        onClose();
    };

    return (
        <View style={styles.backdrop}>
            <View style={styles.modal}>
                <Text style={[styles.logo, {}]}>{t('order_success')}</Text>
                <View style={styles.content}>
                    <AntDesign name="checkcircle" size={68} color="#FFF" style={{ paddingBottom: 30 }} />
                    <Text style={[styles.logo, styles.orderId]}>{t('order_id')}: #{order.orderCode || "#246-244"}</Text>
                    <TouchableOpacity style={[styles.button, styles.fullWidthButton]} onPress={onClose}>
                        <Text style={styles.buttonText}>{t('save_for_later')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.orangeButton, styles.fullWidthButton]} onPress={handleCloseInvoice}>
                        <Text style={styles.buttonText}>{t('close_invoice')}</Text>
                    </TouchableOpacity>
                    <View style={{ height: 1, backgroundColor: '#404040', width: "100%", marginVertical: 8}} />
                    <TouchableOpacity style={[styles.button, styles.fullWidthButton, { marginTop: 10, marginBottom: 10, backgroundColor: "black" }]} onPress={() => {
                        new Promise(() => sendData('fiscat/AEE|PEJ|4'))
                    }}>
                        <Text style={[styles.buttonText, { color: 'white' }]}>{t("reprint_invoice")}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <HappyAlert
                visible={isAlertVisible}
                onConfirm={() => { setIsAlertVisible(false); handleOrderClose(); }}
                onCancel={() => setIsAlertVisible(false)}
                type="info"
                message={t('confirm_close_order')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {

        padding: 20,
        minHeight: "fit-content",
        backgroundColor: '#81F174',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#FFF',
    },
    orderId: {
        fontSize: 28,
        textAlign: 'center',
        color: 'green',
        fontWeight: "bold",
        marginBottom: 20,
        marginTop: 10,
    },
    button: {
        backgroundColor: 'green',
        paddingVertical: 15,
        paddingHorizontal: 15,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
    orangeButton: {
        backgroundColor: '#FFA500',
        paddingHorizontal: 15,
        paddingVertical: 15,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    fullWidthButton: {
        width: '100%',
    },
});

export default SuccessModal;