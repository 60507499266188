import React, { useContext, useState } from 'react';
import {
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
    Dimensions,
    Platform, Alert
} from 'react-native';
import { priceFormatter } from '../helpers/numbers';
import { CartContext } from "../helpers/CartContext";
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from "react-i18next";

const DiscountModal = ({ visible, onClose, totalPrice, itemPrice, selectedCartItemID, openFrom }) => {
    const { t } = useTranslation();
    const { addDiscount, productsPrice, cartItems, setCartItems, discountsForWholeCart, setDiscountsForWholeCart, alreadySentCartItems, setAlreadySentCartItems } = useContext(CartContext);
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');

    const screenWidth = Dimensions.get('window').width;
    const modalWidth = screenWidth > 768 ? '35%' : '80%';

    const handleDiscountAmountChange = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        setDiscountAmount(numericValue);
        setDiscountPercentage('');
    };

    const handleDiscountPercentageChange = (value) => {
        let numericValue = value.replace(/[^0-9]/g, '');
        if (parseFloat(numericValue) > 100) {
            numericValue = '100';
        }
        setDiscountPercentage(numericValue);
        setDiscountAmount('');
    };

    const handleSaveDiscount = () => {
        const totalAmount = itemPrice || productsPrice;
        const discountValue = discountAmount ? parseFloat(discountAmount) : (discountPercentage ? (totalAmount * parseFloat(discountPercentage) / 100) : 0);

        const showAlert = (message) => {
            if (Platform.OS === 'web') {
                alert(message);
            } else {
                Alert.alert(t("error"), message);
            }
        };

        if (discountValue > totalAmount) {
            showAlert(t("discount_error_amount"));
            return;
        }

        if (discountPercentage && parseFloat(discountPercentage) > 100) {
            showAlert(t("discount_error_percentage"));
            return;
        }

        if (discountAmount && parseFloat(discountAmount) > totalAmount) {
            showAlert(t("discount_error_item_amount"));
            return;
        }

        if (discountAmount && openFrom) {
            addDiscount(selectedCartItemID, { amount: discountAmount, percentage: 0 }, openFrom);
        } else if (discountPercentage && openFrom) {
            addDiscount(selectedCartItemID, { amount: 0, percentage: discountPercentage }, openFrom);
        }
        setDiscountPercentage("");
        setDiscountAmount("");
        onClose();
    };

    const handleDeleteDiscount = () => {
        if (openFrom === "cartTotal") {
            setDiscountsForWholeCart({ amount: 0, percentage: 0 });
        }
        const updatedCartItems = cartItems.map(item => {
            if (item._id === selectedCartItemID) {
                item.discounts = undefined;
                item.remotePrice = item.price * item.quantity;
            }
            return item;
        });
        const updatedAlreadySentCartItems = alreadySentCartItems.map(item => {
            if (item._id === selectedCartItemID) {
                item.discounts = undefined;
                item.remotePrice = item.price * item.amount;
            }
            return item;
        });
        setAlreadySentCartItems(updatedAlreadySentCartItems);
        setCartItems(updatedCartItems);
    };

    const selectedItem = cartItems.find(item => item._id === selectedCartItemID);
    const discounts = selectedItem && selectedItem?.discounts;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.centeredView}>
                <View style={[styles.modalView, { width: modalWidth }]}>
                    <Text style={styles.modalTitle}>{t("discount_title")}</Text>
                    <Text style={styles.originalAmount}>{t("original_amount")}: {priceFormatter(itemPrice || productsPrice)}</Text>

                    {discounts && (
                        <>
                            <Text style={styles.originalAmount}>{t("discounted_price")}: {priceFormatter((itemPrice || productsPrice) - (discounts?.amount || 0) - ((itemPrice || productsPrice) * (discounts?.percentage || 0) / 100))}</Text>
                            <View style={styles.discountsContainer}>
                                {openFrom === "cartItem" ? (
                                    <>
                                        <Text style={styles.discountsTitle}>{t("current_discount")}:</Text>
                                        <ScrollView style={styles.scrollView}>
                                            <View style={styles.discountRow}>
                                                <Text style={styles.discountText}>
                                                    {discounts.amount > 0 ? `${priceFormatter(discounts?.amount)}` : `${discounts?.percentage}%`}
                                                </Text>
                                                <TouchableOpacity onPress={() => handleDeleteDiscount()} style={styles.deleteButton}>
                                                    <Icon name="x" size={20} color="red" />
                                                </TouchableOpacity>
                                            </View>
                                        </ScrollView>
                                    </>
                                ) : <></>}
                            </View>
                        </>
                    )}
                    {(openFrom === "cartTotal" && (discountsForWholeCart?.amount > 0 || discountsForWholeCart?.percentage > 0)) && (
                        <>
                            <Text style={styles.discountsTitle}>{t("cart_discount")}:
                                {discountsForWholeCart.amount > 0 ? `${priceFormatter(discountsForWholeCart?.amount)}` : `${discountsForWholeCart?.percentage}%`}
                            </Text>
                            <TouchableOpacity onPress={() => handleDeleteDiscount()} style={styles.deleteButton}>
                                <Icon name="x" size={20} color="red" />
                            </TouchableOpacity>
                        </>
                    )}
                    <TextInput
                        style={styles.textInput}
                        placeholder={t("discount_amount_placeholder")}
                        value={discountAmount}
                        onChangeText={handleDiscountAmountChange}
                        keyboardType="numeric"
                    />
                    <TextInput
                        style={styles.textInput}
                        placeholder={t("discount_percentage_placeholder")}
                        value={discountPercentage}
                        onChangeText={handleDiscountPercentageChange}
                        keyboardType="numeric"
                    />
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={[styles.button, styles.closeButton]} onPress={() => { setDiscountPercentage(""); setDiscountAmount(""); onClose() }}>
                            <Text style={styles.buttonText}>{t("close")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, styles.saveButton]} onPress={handleSaveDiscount}>
                            <Text style={styles.buttonText}>{t("save")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalView: {
        margin: 20,
        backgroundColor: '#fff',
        borderRadius: 20,
        paddingTop: 8,
        paddingHorizontal: 30,
        paddingBottom: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        maxHeight: '80%',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    originalAmount: {
        fontSize: 16,
        marginBottom: 20,
    },
    discountsContainer: {
        marginBottom: 20,
        maxHeight: '30%',
    },
    scrollView: {
        maxHeight: '100%',
    },
    discountsTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    discountRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
    },
    discountText: {
        fontSize: 14,
        color: '#888',
    },
    deleteButton: {
        padding: 10,
    },
    textInput: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 20,
        width: '100%',
        paddingHorizontal: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
    },
    button: {
        flex: 1,
        padding: 18,
        alignItems: 'center',
        borderRadius: 4,
    },
    closeButton: {
        backgroundColor: 'grey',
        marginRight: 10,
    },
    saveButton: {
        backgroundColor: '#F76E24',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
});

export default DiscountModal;