import React, { useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, TouchableOpacity, View, RefreshControl } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { SystemContext } from "../helpers/SystemContext";
import TimeAgo from "react-native-timeago";
import { Surface } from "react-native-paper";
import LottieView from "lottie-react-native";
import IonIcons from "react-native-vector-icons/Ionicons";
import { getAccessToken } from "../helpers/storage";

export default function TablesBookingsScreen({ navigation, route }) {
    const { bookings } = useContext(SystemContext);
    const [refreshing, setRefreshing] = useState(false);

    const fetchBookings = async () => {
        const token = await getAccessToken();

        try {
            const response = await fetch("https://api.happygastro.hu/api/booking/restaurant", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
        } catch (error) {
            console.error("Booking request failed:", error);
        }
    };

    useEffect(() => {
        fetchBookings();
    }, []);

    const onRefresh = async () => {
        setRefreshing(true);
        await fetchBookings();
        setRefreshing(false);
    };

    if (bookings.length === 0) {
        return (
            <LinearGradient
                colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
                style={styles.animationBackground}
            >
                <View style={[styles.animationContainer, { justifyContent: 'center' }]}>
                    <LottieView source={require('../../assets/animations/no-data-here.json')} autoPlay loop style={styles.animation} />
                    <Text style={styles.warningText}>Jelenleg nincs elérhető asztalfoglalás.</Text>
                </View>
            </LinearGradient>
        );
    }

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={[styles.background, { width: "100%" }]}
        >
            <View style={[styles.container, { width: "100%" }]}>
                <FlatList
                    style={{ width: '100%' }}
                    contentContainerStyle={{ width: '100%' }}
                    data={bookings.map(booking => ({
                        id: '#' + booking._id,
                        fullName: booking?.guestInfo?.name,
                        phone: booking?.guestInfo?.phone,
                        guests: booking.guests,
                        dateFrom: booking.dateFrom,
                        dateTo: booking.dateTo,
                        comment: booking.comment,
                        status: booking.status,
                        denyReason: booking.denyReason,
                        bookingData: booking
                    }))}
                    renderItem={({ item }) => (
                        <TouchableOpacity style={[styles.row, { marginVertical: 10, width: '100%' }]}
                                          onPress={() => navigation.navigate('BookingDetailsScreen', { bookingData: item.bookingData })}>
                            <Surface style={[styles.surface, {backgroundColor: item.status === "accepted" ? "#cbfaa9" : "#f6abb4"}]} elevation={4}>
                                <View style={{flex: 4, width: '100%', flexDirection: 'row', display: 'flex'}}>
                                    <View style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', display: 'flex'}}>
                                        <Text style={{ fontWeight: "700", flex: 1 }}>
                                            <TimeAgo time={new Date(item.dateFrom)} interval={10} /> - <TimeAgo time={new Date(item.dateTo)} interval={10} />
                                        </Text>
                                        <Text style={{ flex: 1 }}>
                                            {new Date(item.dateFrom).toLocaleString("hu-HU")} - {new Date(item.dateTo).toLocaleString("hu-HU")}
                                        </Text>
                                    </View>
                                    <View style={{ flex: 5, alignItems: 'center', justifyContent: 'center', }}>
                                        <Text style={{ fontWeight: "700", flexDirection: 'column' }}>
                                            {item.fullName || "Nincs név megadva"}
                                        </Text>
                                        <Text style={{ fontWeight: "700", flexDirection: 'column' }}>
                                            {item.phone || "Nincs telefonszám megadva"}
                                        </Text>
                                    </View>
                                    <View style={{flex: 3, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                        <Text style={{ flex: 1, textAlign: 'right' }}>Megjegyzés:</Text>
                                        <Text style={{ fontWeight: "700", flex: 1, textAlign: 'right' }}>{item.comment || "Nincs megjegyzés"}</Text>
                                    </View>
                                    <View style={{flex: 3, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', display: 'flex'}}>
                                        <Text style={{ flex: 1, textAlign: 'right' }}>Vendégek:</Text>
                                        <Text style={{ fontWeight: "700", flex: 1, textAlign: 'right' }}>{item.guests}</Text>
                                    </View>
                                    <TouchableOpacity
                                        style={{flex: 1, alignItems: 'flex-end', justifyContent: 'center', display: 'flex'}}
                                        onPress={() => {
                                            if (item.status !== "accepted") {
                                                alert(item.denyReason || "Nincs megadva az elutasítás oka!");
                                            }
                                        }}>
                                        <IonIcons name={item.status === "accepted" ? "checkmark-sharp" : "ban"} size={24} color={item.status === "accepted" ? "green" : "red"} />
                                    </TouchableOpacity>
                                </View>
                            </Surface>
                        </TouchableOpacity>
                    )}
                    keyExtractor={(item) => item.id}
                    refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                    }
                />
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    animation: {
        width: 150,
        height: 150,
        alignSelf: 'center',
    },
    warningText: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    surface: {
        padding: 8,
        height: 60,
        width: '97%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        marginHorizontal: 15,
        alignSelf: 'center',
        marginLeft: 15,
        marginRight: 15
    },
    animationContainer: {
        flex: 1,
        width: '50%',
        height: '50%',
        paddingTop: 16,
        marginBottom: 150,
        alignSelf: 'center',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        flex: 1,
        flexGrow: 1,
        width: '100%',
        height: '100%',
        paddingTop: 16,
        marginBottom: 150,
        alignSelf: 'center',
        alignItems: 'center'
    },
    animationBackground: {
        flex: 1,
        width: '100%'
    },
    background: {
        flex: 1,
        width: '100%'
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});