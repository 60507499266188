import React, { useState, useEffect, useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Platform, Dimensions } from 'react-native';
import { SegmentedButtons } from "react-native-paper";
import { LinearGradient } from "expo-linear-gradient";
import OrderRow from "../components/OrderRow";
import { OrdersContext } from '../helpers/OrdersContext';

const { height, width } = Dimensions.get('window');


const OrderListScreen = ({ route, navigation }) => {
    const { orders } = useContext(OrdersContext);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('Összes');

    useEffect(() => {
        applyFilter(selectedFilter);
    }, [orders, selectedFilter]);

    const applyFilter = (filter) => {
        setSelectedFilter(filter);
        let filtered = [];

        if (filter === 'Összes') {
            const cancelledOrders = orders.filter(order => order.status === 'cancelled' || order.storno);
            const openOrders = orders.filter(order => order.status !== 'cancelled' && !order.storno && order.status !== 'closed');
            const closedOrders = orders.filter(order => order.status === 'closed' && order.status !== 'cancelled' && !order.storno);

            filtered = [
                ...cancelledOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
                ...openOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
                ...closedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            ];
        } else if(filter === 'Sztornó'){
            const cancelledOrders = orders.filter(order => order.status === 'cancelled' || order.storno);

            filtered = [
                ...cancelledOrders.filter(order => filterCriteria(order, filter)).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            ];
        } else {
            const notClosedOrders = orders.filter(order => order.status !== 'cancelled' && !order.storno && order.status !== 'closed');
            const closedOrders = orders.filter(order => order.status === 'closed' && order.status !== 'cancelled' && !order.storno);

            filtered = [
                ...notClosedOrders.filter(order => filterCriteria(order, filter)).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
                ...closedOrders.filter(order => filterCriteria(order, filter)).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ];
        }

        setFilteredOrders(filtered);
    };

    const filterCriteria = (order, filter) => {
        switch (filter) {
            case 'Kedvezményes':
                return order?.discount?.amount > 0 || order?.discount?.percentage > 0 || order?.discountPrice > 0 || order?.discountAmount > 0;
            case 'Sztornó':
                return order?.storno === true || order?.status === "cancelled";
            case 'Asztalos':
                return order.orderType === 'dineIn';
            case 'Házhoz':
                return order.orderType === 'delivery';
            case 'Elvitel':
                return order.orderType === 'takeAway';
            default:
                return true;
        }
    };

    return (
        <LinearGradient colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']} style={styles.background}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <ScrollView horizontal nestedScrollEnabled contentContainerStyle={{ justifyContent: 'center' }}>
                        <SegmentedButtons
                            density={"small"}
                            style={{ marginTop: 7, flex: 1, width: '100%', height: 40 }}
                            value={selectedFilter}
                            onValueChange={applyFilter}
                            theme={{ colors: { primary: '#fde1b6', onSecondaryContainer: 'white', secondaryContainer: '#F1B44C' }, fonts: { regular: { fontWeight: 'bold' } } }}
                            buttons={['Összes', 'Kedvezményes', 'Sztornó', 'Asztalos', 'Házhoz', 'Elvitel'].map(filter => ({ checkedColor: 'black', value: filter, label: filter }))}
                        />
                    </ScrollView>
                </View>

                <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewContent}
                    nestedScrollEnabled={true}  // Enable nested scrolling
                >
                    {filteredOrders.map(order => (
                        <OrderRow
                            key={order.orderCode}
                            order={order}
                            onPress={() => navigation.navigate('OrderDetailsScreen', { orderData: order, orderId: order._id })}
                        />
                    ))}
                </ScrollView>
            </View>
        </LinearGradient>

    );
};

const styles = StyleSheet.create({
    container: {
        height: Platform.OS === 'web' ? '100vh' : height * 0.95,
        justifyContent: 'center',
    },
    background: {
        flex: 1,
    },
    header: {
        padding: 16,
        backgroundColor: '#fff',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    scrollView: {
        width: '99%',
        height: Platform.OS === 'web' ? '75vh' : height - 75,
        paddingBottom: 35,
    },
    scrollViewContent: {
        flexGrow: 1,
        paddingBottom: 20,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 8,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '100%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    filterOption: {
        padding: 10,
        fontSize: 18,
    },
    buttons: {
        backgroundColor: '#f1b34b',
        padding: 5,
        borderRadius: 1,
    },
    pagesContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
});

export default OrderListScreen;