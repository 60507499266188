import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from "react-native-vector-icons/Feather";

const PinPad = ({ onButtonClick }) => {
    const buttons = [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['00', '0', 'X']
    ];

    return (
        <View style={styles.pinpadContainer}>
            {buttons.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.pinpadRow}>
                    {row.map((button, buttonIndex) => (
                        <TouchableOpacity
                            key={buttonIndex}
                            style={styles.pinpadButton}
                            onPress={() => onButtonClick(button)}
                        >
                            {button === 'X' ? <Icon name="check" color="black" size={24} /> :  <Text style={styles.pinpadText}>{button}</Text>}
                        </TouchableOpacity>
                    ))}
                </View>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    pinpadContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    pinpadRow: {
        flexDirection: 'row',
        width: '100%',
        height: '25%',
    },
    pinpadButton: {
        flex: 1,
        padding: 10,
        borderWidth: 0.5,
        borderColor: 'rgba(255,255,255,0.5)',
        backgroundColor: 'rgba(255,221,0,0)',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pinpadText: {
        fontSize: 24,
        textAlign: 'center',
    },
    '@media (max-width: 600px)': {
        pinpadButton: {
            fontSize: 24,
            padding: 10,
        },
        pinpadButtonText: {
            fontSize: 24,
        },
    },
});

export default PinPad;