import React, {useEffect, useState} from 'react';
import { Modal, View, Text, TextInput, Button, TouchableOpacity, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import {SystemContext} from "./SystemContext";

const NewBookingModal = ({ visible, onClose, onSubmit }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [guestCount, setGuestCount] = useState('1');
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [duration, setDuration] = useState('2');
    const [notes, setNotes] = useState('');
    const {restaurantPlace } = React.useContext(SystemContext);

    const handleDateConfirm = (params) => {
        setDate(params.date);
        setShowDatePicker(false);
    };

    const handleTimeConfirm = ({ hours, minutes }) => {
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
        setTime(formattedTime);
        setShowTimePicker(false);
    };

    const handleSubmit = () => {
        const name = `${firstName} ${lastName}`;
        const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        const dateFrom = `${formattedDate}T${time}`; // Combine date and time

        const bookingData = { name, phone, guests: guestCount, dateFrom , bookingLength: duration, comment: notes, placeId : restaurantPlace?._id };
        onSubmit(bookingData);
        onClose();
    };

    return (
        <Modal visible={visible} animationType="slide" transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>Új foglalás rögzítése</Text>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={styles.label}>Vendég vezetékneve</Text>
                            <TextInput
                                style={styles.input}
                                placeholder="Vendég vezetékneve"
                                value={firstName}
                                onChangeText={setFirstName}
                            />
                        </View>
                        <View style={{ flex: 1, marginLeft: 5 }}>
                            <Text style={styles.label}>Keresztnév</Text>
                            <TextInput
                                style={styles.input}
                                placeholder="Keresztnév"
                                value={lastName}
                                onChangeText={setLastName}
                            />
                        </View>
                    </View>

                    <Text style={styles.label}>Telefonszám</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Telefonszám"
                        keyboardType="phone-pad"
                        value={phone}
                        onChangeText={setPhone}
                    />

                    <Text style={styles.label}>Vendégek száma</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Vendégek száma"
                        keyboardType="numeric"
                        value={guestCount}
                        onChangeText={setGuestCount}
                    />

                    <Text style={styles.label}>Foglalás dátuma</Text>
                    <TouchableOpacity onPress={() => setShowDatePicker(true)} style={styles.datePickerButton}>
                        <Text style={styles.datePickerText}>{date ? date.toLocaleDateString('hu-HU') : 'Válasszon dátumot'}</Text>
                    </TouchableOpacity>

                    <DatePickerModal
                        label="Válassz egy dátumot"
                        locale="hu"
                        mode="single"
                        saveLabel="Mentés"
                        visible={showDatePicker}
                        date={date}
                        onDismiss={() => setShowDatePicker(false)}
                        onConfirm={handleDateConfirm}
                    />

                    <Text style={styles.label}>Foglalás időpontja</Text>
                    <TouchableOpacity onPress={() => setShowTimePicker(true)} style={styles.datePickerButton}>
                        <Text style={styles.datePickerText}>{time ? time : 'Válasszon időpontot'}</Text>
                    </TouchableOpacity>

                    <TimePickerModal
                        label="A válassz egy időpontot"
                        visible={showTimePicker}
                        confirmLabel="Mentés"
                        cancelLabel="Mégse"
                        onDismiss={() => setShowTimePicker(false)}
                        onConfirm={handleTimeConfirm}
                        hours={12}
                        minutes={0}
                    />

                    <Text style={styles.label}>Foglalás időtartama</Text>
                    <View style={styles.pickerContainer}>
                        <Picker
                            selectedValue={duration}
                            onValueChange={(itemValue) => setDuration(itemValue)}>
                            <Picker.Item label="30 perc" value="0.5" />
                            <Picker.Item label="1 óra" value="1" />
                            <Picker.Item label="2 óra" value="2" />
                            <Picker.Item label="3 óra" value="3" />
                            <Picker.Item label="4 óra" value="4" />
                            <Picker.Item label="5 óra" value="5" />
                            <Picker.Item label="6 óra" value="6" />
                            <Picker.Item label="7 óra" value="7" />
                            <Picker.Item label="8 óra" value="8" />
                        </Picker>
                    </View>

                    <Text style={styles.label}>Megjegyzés a foglaláshoz</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Megjegyzés a foglaláshoz"
                        value={notes}
                        onChangeText={setNotes}
                    />

                    <View style={styles.buttonRow}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Button title="Mégsem" onPress={onClose} color={'#000'} />
                        </View>
                        <View style={{ flex: 1, marginLeft: 5 }}>
                            <Button title="Foglalás mentése" color={"#ff8125"} onPress={handleSubmit} />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: 420,
        elevation: 5,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 15,
    },
    datePickerButton: {
        padding: 10,
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        marginBottom: 15,
        alignItems: 'center',
    },
    datePickerText: {
        color: '#333',
    },
    pickerContainer: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 15,
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default NewBookingModal;